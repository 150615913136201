import { useMemo } from 'react';

import { PaginatedExploreExhibitionsQuery } from 'graphql/__generated__/PaginatedExploreExhibitionsQuery.graphql';
import {
  DropPhaseEnum,
  UserSearchAccountTypesEnum,
} from 'types/__generated__/graphql';

import CuratorsFilter from 'components/filters/CuratorsFilter';
import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import {
  SortExhibitionsFilter,
  SortExhibitionsFilterValue,
} from 'components/filters/SortFilter';
import ToggleFilter from 'components/filters/ToggleFilter';
import UsersFilter from 'components/filters/UsersFilter';
import useFiltersGTM, { FilterType } from 'utils/GTM/filters';

import useQueryParamMemo from '../hooks/useQueryParamMemo';

export interface ExhibitionsFiltersState {
  artists: Array<string>;
  curators: Array<string>;
  query: string;
  showFollowingArtists: boolean;
  sort: SortExhibitionsFilterValue;
  status: Partial<Record<DropPhaseEnum, boolean>>;
}

export const EXHIBITIONS_FILTERS_DEFAULT_STATE: ExhibitionsFiltersState = {
  artists: [],
  curators: [],
  query: '',
  showFollowingArtists: false,
  sort: {},
  status: {},
};

export const useExhibitionsFiltersQueryValues = (
  filters: ExhibitionsFiltersState
): PaginatedExploreExhibitionsQuery['variables'] => {
  const artists = useMemo(
    () => (filters.artists?.length ? filters.artists : undefined),
    [filters.artists]
  );
  const curators = useMemo(
    () => (filters.curators?.length ? filters.curators : undefined),
    [filters.curators]
  );
  const showFollowingArtists = useMemo(
    () => (filters.showFollowingArtists ? true : undefined),
    [filters.showFollowingArtists]
  );
  const status = useQueryParamMemo(filters.status ?? {}) as DropPhaseEnum[];

  return {
    artists,
    curators,
    first: 9,
    nftMetadatasCount: 11,
    query: filters.query,
    showFollowingArtists,
    sortOrder: filters.sort?.order,
    sortType: filters.sort?.type,
    status,
  };
};

interface ExhibitionsFiltersProps {
  onChange: (state: Partial<ExhibitionsFiltersState>) => void;
  state: ExhibitionsFiltersState;
}

export default function Filters({ state, onChange }: ExhibitionsFiltersProps) {
  const { updateFilter } = useFiltersGTM();
  return (
    <FilterScrollContainer>
      <FiltersSection inline inlineMobile>
        <FiltersGroup>
          <FiltersGroup title="Sorting">
            <SortExhibitionsFilter
              value={state.sort}
              onChange={(sort) => {
                onChange({ sort });
                updateFilter(FilterType.SortFilter, sort);
              }}
            />
          </FiltersGroup>
        </FiltersGroup>

        <FiltersGroup title="Filters">
          <ToggleFilter
            title="Sold Out"
            onToggle={() => {
              onChange({
                status: {
                  [DropPhaseEnum.SoldOut]: !state.status[DropPhaseEnum.SoldOut],
                },
              });
              updateFilter(FilterType.SoldOutFilter, {
                soldOut: !state.status[DropPhaseEnum.SoldOut],
              });
            }}
            isSelected={!!state.status[DropPhaseEnum.SoldOut]}
          />
          <UsersFilter
            value={{
              isShowFollowing: state.showFollowingArtists,
              userNames: state.artists,
            }}
            onChange={(value) => {
              onChange({
                artists: value.userNames,
                showFollowingArtists: value.isShowFollowing,
              });
              updateFilter(FilterType.ArtistsFilter, value);
            }}
            accountSearchType={UserSearchAccountTypesEnum.Artist}
          />
          <CuratorsFilter
            onChange={(curators) => onChange({ curators })}
            values={state.curators}
          />
        </FiltersGroup>
      </FiltersSection>
    </FilterScrollContainer>
  );
}

import { ArtistsFiltersState } from './artists/Filters';
import { ArtworksFiltersState } from './artworks/Filters';
import { EditorialsFiltersState } from './editorials/Filters';
import { ExhibitionsFiltersState } from './exhibitions/Filters';
import { SeriesFiltersState } from './series/Filters';

export enum ExploreType {
  Artists = 'Artists',
  Artworks = 'Artworks',
  Editorial = 'Editorial',
  Exhibitions = 'Exhibitions',
  Series = 'Series',
}

export type FiltersStateType =
  | ArtistsFiltersState
  | ArtworksFiltersState
  | EditorialsFiltersState
  | ExhibitionsFiltersState
  | SeriesFiltersState;

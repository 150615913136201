import { useCallback, useState, useTransition } from 'react';
import { noop } from 'lodash';
import { useMutation } from 'react-relay';
import { Link } from 'react-router-dom';

import {
  MPActionButton,
  MPColorClass,
  MPDivider,
  MPFonts,
  useMPConfirmDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountDeletePaypalWithdrawal, {
  AccountDeletePaypalWithdrawalMutation,
} from 'graphql/__generated__/AccountDeletePaypalWithdrawalMutation.graphql';

import ErrorDisplay from 'components/Error';
import InfoTooltip from 'components/InfoTooltip';
import ROUTES from 'constants/Routes';
import useSession, { useRefreshSession } from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';
import {
  generateShortEthPriceString,
  generateShortUsdPriceString,
} from 'utils/currency/generatePricing';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import promisifyMutation from 'utils/promisifyMutation';

import CoinbaseSection from './CoinbaseSection';
import ConnectDigitalWalletSection from './ConnectDigitalWalletSection';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

function DeletePaypalButton() {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const refreshSession = useRefreshSession();
  const [deletePaypalWithdrawalMutation] =
    useMutation<AccountDeletePaypalWithdrawalMutation>(
      AccountDeletePaypalWithdrawal
    );
  const [isTransitioning, startTransition] = useTransition();

  const deletePaypal = useCallback(async () => {
    try {
      setError(undefined);
      setIsLoading(true);
      await promisifyMutation(deletePaypalWithdrawalMutation)({});

      startTransition(refreshSession);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [deletePaypalWithdrawalMutation, refreshSession]);

  const [, setDialogOpen, ConfirmDialog] = useMPConfirmDialog({
    content: (
      <>
        <div
          className={joinClasses(
            MPFonts.textSmallMedium,
            pageStyles.dialogBody
          )}
        >
          <ErrorDisplay
            className={CSSGlobal.TextAlign.Centered}
            error={error}
          />
          <div>Are you sure you want to disconnect your PayPal Account?</div>
        </div>
      </>
    ),
    onCancel: noop,
    onConfirm: deletePaypal,
    title: 'Disconnect PayPal Account',
  });

  return (
    <>
      <MPActionButton
        size="large"
        fullWidth
        variant="secondary"
        onClick={() => setDialogOpen(true)}
        isLoading={isLoading || isTransitioning}
      >
        Disconnect&nbsp;PayPal
      </MPActionButton>
      {ConfirmDialog}
    </>
  );
}

function WithdrawalSection({ hideTitle }: { hideTitle?: boolean }) {
  const session = useSession();

  const usdBalance =
    session.account.payoutUsdBalance ||
    0 + session.account.royaltyUsdBalance ||
    0;
  const ethBalance =
    session.account.payoutEthBalance ||
    0 + session.account.royaltyEthBalance ||
    0;

  return (
    <div>
      {!hideTitle && (
        <div
          className={joinClasses(MPFonts.headline4, pageStyles.sectionTitle)}
        >
          Withdraw Settings
        </div>
      )}
      <div
        className={joinClasses(
          pageStyles.sectionExplanation,
          MPFonts.paragraphSmall
        )}
      >
        When you sell artwork or receive royalties, we will transfer the funds
        to you in the currency used by the collector. You can view your sales
        history&nbsp;
        <Link
          reloadDocument
          to={ROUTES.STORE.PURCHASE_HISTORY()}
          className={MPColorClass.CommonBlack}
        >
          here
        </Link>
        .
      </div>
      <div
        className={joinClasses(pageStyles.section, MPFonts.textSmallRegular)}
      >
        <div
          className={joinClasses(
            MPFonts.textSmallSemiBold,
            CSSGlobal.Flex.InlineRowCenterAlign
          )}
        >
          USD Withdrawal&nbsp;
          <InfoTooltip
            title="Note that it will take up to 2 weeks for you to receive funds in your account."
            placement="right"
          />
        </div>
        {!!usdBalance && (
          <div className={CSSGlobal.Flex.InlineRowCenterAlign}>
            <span>Available Balance:</span>&nbsp;
            <span>{generateShortUsdPriceString(usdBalance)}</span>
            &nbsp;
            <InfoTooltip
              title="USD available for you to withdraw into your PayPal account. We will pay you in the currency that the collector uses to purchase."
              placement="right"
            />
          </div>
        )}
        <div>
          {session.account.paypalPayoutWallet ? (
            <>
              You will automatically receive USD payments in your PayPal
              account.
            </>
          ) : (
            <>
              After you connect your PayPal account, we will automatically
              transfer any available balance.
            </>
          )}
        </div>
        {session.account.paypalPayoutWallet ? (
          <DeletePaypalButton />
        ) : (
          <a href={session.paypalOauthUrl}>
            <MPActionButton size="large" fullWidth variant="primary">
              Connect&nbsp;PayPal
            </MPActionButton>
          </a>
        )}
        <MPDivider />
        <div
          className={joinClasses(
            MPFonts.textSmallSemiBold,
            CSSGlobal.Flex.InlineRowCenterAlign
          )}
        >
          ETH Withdrawal&nbsp;
          <InfoTooltip
            title="Note that it will take up to 24 hours for you to receive funds in your account."
            placement="right"
          />
        </div>
        {!!ethBalance && (
          <div className={CSSGlobal.Flex.InlineRowCenterAlign}>
            <span>Available Balance:</span>&nbsp;
            <span>{generateShortEthPriceString(ethBalance)}</span>
            &nbsp;
            <InfoTooltip
              title="ETH available for you to withdraw into your digital wallet, or use for making purchases at MakersPlace. We will pay you in the currency that the collector uses to purchase."
              placement="right"
            />
          </div>
        )}
        <div>
          {session.account.ethPayoutWallet ? (
            <>
              You will automatically receive ETH payments in your connected
              digital wallet.
            </>
          ) : (
            <>
              Connect either a Digital Wallet or a Coinbase account and your
              available ETH balance will be automatically transferred.
            </>
          )}{' '}
          <span className={MPFonts.textSmallMedium}>
            Note that it will take up to 24 hours for you to receive funds in
            your account.
          </span>
        </div>
        {!session.account.ethPayoutWallet?.coinbaseCustomerId ? (
          <>
            <ConnectDigitalWalletSection />
            <CoinbaseSection />
          </>
        ) : (
          <>
            <CoinbaseSection />
            <ConnectDigitalWalletSection />
          </>
        )}
      </div>
    </div>
  );
}

export default withDefaultErrorBoundary(WithdrawalSection, {
  suspenseless: true,
});

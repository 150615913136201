import { PureComponent, useCallback, useEffect, useState } from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';

import NFTlogViewMutationType, {
  NFTlogViewMutation,
} from 'graphql/__generated__/NFTlogViewMutation.graphql';
import NFTSType, { NFTsQuery } from 'graphql/__generated__/NFTsQuery.graphql';
import {
  ContractNameEnum,
  GraphPageViewObjectType,
  MpErrors,
} from 'types/__generated__/graphql';

import { STATSIG_EVENT } from 'constants/Statsig';
import { EcommerceSourceType } from 'GTM';
import PasswordPage, { ErrorType } from 'pages/private/PasswordPage';
import { NFTType } from 'types/graphql/NFT';
import { LOCAL_STORAGE_KEYS } from 'utils/localStorageUtils';

import ProductContainer from './ProductContainer';
import ProductLayoutContainer from './ProductLayoutContainer';

export function useGetNFTFromPreloadedQuery(queryRef) {
  const { nfts } = usePreloadedQuery<NFTsQuery>(NFTSType, queryRef);
  const nft = nfts.edges.length > 0 ? nfts.edges[0].node : null;
  return nft;
}

const generateProductSessionStorageKey = (nftSlug: string) =>
  `${LOCAL_STORAGE_KEYS.PRODUCT_PAGE_PASSWORD}-${nftSlug}`;

export const setPasswordForProduct = (slug: string, password: string) => {
  sessionStorage.setItem(generateProductSessionStorageKey(slug), password);
};

function ProductPage({
  password = undefined,
  nftSlug,
}: {
  nftSlug: string;
  password?: string;
}) {
  const [pageViewLogged, setPageViewLogged] = useState<boolean>(false);
  const [nftMetadata, setMetadata] = useState<NFTType['metadata']>(null);
  // This fetches minted and non minted token incase not live product.

  const [nftViewMutation] = useMutation<NFTlogViewMutation>(
    NFTlogViewMutationType
  );

  const nftPageView = useCallback(() => {
    if (nftMetadata && !pageViewLogged) {
      setPageViewLogged(true);
      nftViewMutation({
        variables: {
          objectId: parseInt(nftMetadata.pk, 10),
          objectType: GraphPageViewObjectType.Nft,
        },
      });
    }
  }, [nftMetadata, pageViewLogged, nftViewMutation]);

  useEffect(() => {
    nftPageView();
  }, [nftPageView]);

  return (
    <ProductLayoutContainer id="productPage">
      <ProductContainer
        approvalRegistryContractQuery={{
          variables: { name: ContractNameEnum.ApprovedCreatorRegistry },
        }}
        nftsQuery={{
          variables: { first: 1, password, productSlug: nftSlug },
        }}
        transferCoreContractQuery={{
          variables: { name: ContractNameEnum.TransferCore },
        }}
        password={password}
        setMetadata={setMetadata}
      />
    </ProductLayoutContainer>
  );
}

type RouteParams = {
  nftSlug: string;
};

interface ProtectedProductPageProps {
  nftSlug: string;
  statsigClient: ReturnType<typeof useStatsigClient>;
}

class ProtectedProductPage<T> extends PureComponent<
  ProtectedProductPageProps & T,
  { error: ErrorType; password: string; submitted: boolean }
> {
  static getDerivedStateFromError(error: ErrorType) {
    return { error };
  }

  static willCatch(error: ErrorType) {
    return error?.name === MpErrors.Locked;
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);

    const savedPassword = sessionStorage.getItem(
      generateProductSessionStorageKey(props.nftSlug)
    );
    this.state = { error: null, password: savedPassword, submitted: false };
  }

  componentDidCatch(error: ErrorType) {
    if (!ProtectedProductPage.willCatch(error)) throw error;
  }

  handleSubmit(password: string) {
    const { statsigClient } = this.props;

    this.setState({ submitted: true });
    setPasswordForProduct(this.props.nftSlug, password);

    statsigClient.logEvent(STATSIG_EVENT.MP.PRIVATE_SALE.ENTER_PASSWORD, '', {
      slug: this.props.nftSlug,
      source: EcommerceSourceType.ProductPage,
    });

    this.setState({ password }, this.resetErrorBoundary);
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  render() {
    const { nftSlug } = this.props;
    const { error, password, submitted } = this.state;
    const hasError = ProtectedProductPage.willCatch(error);
    if (hasError) {
      return (
        <PasswordPage
          curatorFullName={error?.additionalData?.curatorFullName}
          curatorUsername={error?.additionalData?.curatorUsername}
          password={password}
          onSubmit={this.handleSubmit}
          isInvalidPassword={!!submitted && hasError}
        />
      );
    }

    return <ProductPage password={password} nftSlug={nftSlug} />;
  }
}

function ProtectedProductPageWrapper<T>(props: Omit<T, 'nftSlug'>) {
  const { nftSlug } = useParams<RouteParams>();
  const statsigClient = useStatsigClient();

  return (
    <ProtectedProductPage
      {...props}
      nftSlug={nftSlug}
      statsigClient={statsigClient}
    />
  );
}

export default ProtectedProductPageWrapper;

import { Route } from 'react-router-dom';

import AccountSettingsPage from './account/AccountSettingsPage';
import AccountPageSplitter from './account/PageSplitter';
import SecuritySection from './account/security';
import Activity from './collection/Activity';
import Collection from './collection/Collection';
import SavedArtworks from './collection/SavedArtworks';
import ContractsPage from './contracts/ContractsPage';
import CreditCard from './wallet/creditCard';
import WalletPageSplitter from './wallet/PageSplitter';
import Withdrawals from './wallet/withdrawals';
import CollectionPage from './collection';
import { PAGESLUG } from './pageSlug';
import WalletPage from './wallet';

const SettingsRoute = (
  <Route path="settings">
    <Route path={PAGESLUG.collection} element={<CollectionPage />}>
      <Route index element={<Collection />} />
      <Route path={PAGESLUG.activity} element={<Activity />} />
      <Route path={PAGESLUG.saved} element={<SavedArtworks />} />
    </Route>
    <Route path={PAGESLUG.wallets} element={<WalletPage />}>
      <Route index element={<WalletPageSplitter />} />
      <Route
        path={PAGESLUG.creditCards}
        element={<CreditCard hideTitle stripeCardQuery={{ variables: {} }} />}
      />
      <Route path={PAGESLUG.withdrawal} element={<Withdrawals hideTitle />} />
    </Route>
    <Route path={PAGESLUG.contracts}>
      <Route index element={<ContractsPage />} />
    </Route>
    <Route path={PAGESLUG.account} element={<AccountSettingsPage />}>
      <Route index element={<AccountPageSplitter />} />
      <Route path={PAGESLUG.security} element={<SecuritySection hideTitle />} />
    </Route>
  </Route>
);

export default SettingsRoute;

import { ReactNode, useCallback, useMemo } from 'react';

import ROUTES from 'constants/Routes';
import useProductState, {
  ProductStateProps,
} from 'hooks/product/useProductState';
import useForceUpdate from 'hooks/useForceUpdate';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import {
  generateShortEthPriceString,
  generateShortUsdPriceString,
} from 'utils/currency/generatePricing';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';

import ProductDefaultLabel from './ProductDefaultLabel';
import ProductEditionLabel from './ProductEditionLabel';
import ProductPriceLabel from './ProductPriceLabel';
import ProductTimerLabel, { TimerType } from './ProductTimerLabel';

import * as styles from 'css/pages/product/ProductLabels.module.css';

interface ProductLabelsProps extends ProductStateProps {
  onProductTimerEnd: () => void;
  children?: ReactNode;
  isSoldOut?: boolean;
}

function ProductLabels({
  nft,
  showDropsTimer,
  showProductAuctionTimer,
  showRankedAuctionTimer,
  children,
  isSoldOut,
  onProductTimerEnd,
}: ProductLabelsProps) {
  const [forceUpdate] = useForceUpdate();
  const {
    showBuyNow,
    showPrice,
    showReservePrice,
    showPresale,
    isPresaleEligible,
  } = useProductState({
    nft,
    showDropsTimer,
    showProductAuctionTimer,
    showRankedAuctionTimer,
  });

  const isSecondaryOwner = nft.metadata.author.id !== nft.currentOwner?.id;
  const showHighestOffer =
    (showProductAuctionTimer || showProductAuctionTimer) && nft.listing.liveBid;

  const askingPriceEth = useMemo(
    () =>
      nft.listing.liveSale
        ? generateShortEthPriceString(nft.listing.lowestAskInEth)
        : generateShortEthPriceString(nft.listing.reservePriceInEth),
    [
      nft.listing.liveSale,
      nft.listing.lowestAskInEth,
      nft.listing.reservePriceInEth,
    ]
  );

  const askingPriceUsd = useMemo(
    () =>
      nft.listing.liveSale
        ? generateShortUsdPriceString(nft.listing.lowestAskInUsd)
        : generateShortUsdPriceString(nft.listing.reservePriceInUsd),
    [
      nft.listing.liveSale,
      nft.listing.lowestAskInUsd,
      nft.listing.reservePriceInUsd,
    ]
  );

  const handleTimerEnded = useCallback(() => {
    forceUpdate();
    onProductTimerEnd();
  }, [forceUpdate, onProductTimerEnd]);

  return (
    <div className={styles.productLabels}>
      {isSoldOut &&
      nft.listing.lastSale?.ethPriceAtSale &&
      !showRankedAuctionTimer ? (
        <ProductPriceLabel
          label="Sold For"
          isUSDPrimary={nft.isCustodialOwner}
          ethPrice={generateShortEthPriceString(
            nft.listing.lastSale.ethPriceAtSale
          )}
          usdPrice={generateShortUsdPriceString(
            nft.listing.lastSale.usdPriceAtSale
          )}
        />
      ) : (
        <>
          {!!(showBuyNow || showPrice) && (
            <ProductPriceLabel
              label={showBuyNow ? 'Buy Now' : 'Price'}
              isUSDPrimary={nft.isCustodialOwner}
              usdPrice={askingPriceUsd}
              ethPrice={askingPriceEth}
            />
          )}
          {!!showReservePrice && (
            <ProductPriceLabel
              label="Reserve Price"
              isUSDPrimary={
                nft.listing.reserveCurrency === CurrencyDisplayMode.USD
              }
              usdPrice={askingPriceUsd}
              ethPrice={askingPriceEth}
            />
          )}
          {!!showPresale && (
            <ProductPriceLabel
              label="Pre-Sale Price"
              isHidden={!isPresaleEligible}
              isPremium={isPresaleEligible}
              isUSDPrimary={nft.isCustodialOwner}
              usdPrice={generateShortUsdPriceString(
                nft.listing.liveSale.custodialPresalePriceUsd
              )}
              ethPrice={generateShortEthPriceString(
                nft.listing.liveSale.custodialPresalePriceEth
              )}
            />
          )}
        </>
      )}
      {!!isSecondaryOwner && (
        <ProductDefaultLabel
          label="Owned By"
          value={generateFormattedUserFullName(nft.currentOwner?.fullName)}
          link={ROUTES.PROFILE.GALLERY.COLLECTED(nft.currentOwner?.username)}
        />
      )}
      {!!showHighestOffer && (
        <ProductPriceLabel
          label="Highest Offer"
          isUSDPrimary
          usdPrice={generateShortUsdPriceString(nft.listing.liveBid.bidInUsd)}
          ethPrice={generateShortEthPriceString(nft.listing.liveBid.bidInEther)}
        />
      )}
      <ProductEditionLabel nft={nft} />
      {!!showRankedAuctionTimer && (
        <ProductTimerLabel
          type={TimerType.RankedAuction}
          startDate={nft.listing.rankedAuction.startsAt}
          endDate={nft.listing.rankedAuction.endsAt}
          onEnd={handleTimerEnded}
        />
      )}
      {!!showProductAuctionTimer && (
        <ProductTimerLabel
          type={TimerType.ProductAuction}
          endDate={nft.listing.productAuction.endsAt}
          onEnd={handleTimerEnded}
        />
      )}
      {!!showDropsTimer &&
        (showPresale && isPresaleEligible ? (
          <ProductTimerLabel
            type={TimerType.Presale}
            startDate={nft.metadata.dropMetadata.drop.presale.startsAt}
            endDate={nft.metadata.dropMetadata.drop.presale.endsAt}
            onEnd={handleTimerEnded}
          />
        ) : (
          <ProductTimerLabel
            type={TimerType.Drop}
            startDate={nft.metadata.dropMetadata.dropsAt}
            upcomingLabel={
              showPresale && !isPresaleEligible
                ? 'Public Sale Starts In'
                : undefined
            }
            onEnd={handleTimerEnded}
          />
        ))}

      {children}
    </div>
  );
}

export default ProductLabels;

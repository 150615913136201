import { useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { MPDropdownSelect } from '@mp-frontend/core-components';

import AccountSearchBasicUserQueryType, {
  AccountSearchBasicUserQuery,
} from 'graphql/__generated__/AccountSearchBasicUserQuery.graphql';
import { UserSearchAccountTypesEnum } from 'types/__generated__/graphql';

import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

interface CuratorsFilterListProps {
  accountsQuery: WithLoadQueryProps<AccountSearchBasicUserQuery>;
  onChange: (values: string[]) => void;
  values: string[];
}

function CuratorsFilterList({
  accountsQuery,
  onChange,
  values,
}: CuratorsFilterListProps) {
  const { accounts } = usePreloadedQuery<AccountSearchBasicUserQuery>(
    AccountSearchBasicUserQueryType,
    accountsQuery.queryRef
  );

  const config: Record<string, string> = useMemo(
    () =>
      accounts.edges.reduce((acc, { node: { username } }) => {
        acc[username] = username;
        return acc;
      }, {} as Record<string, string>),
    [accounts.edges]
  );

  return (
    <MPDropdownSelect
      config={config}
      selectionType="multiple"
      title="Curators"
      values={values}
      onChange={onChange}
    />
  );
}

const BaseCuratorsFilter = withDefaultErrorBoundary(
  withLoadQuery(
    CuratorsFilterList,
    {
      accountsQuery: { concreteRequest: AccountSearchBasicUserQueryType },
    },
    { grouppedLoadingKey: 'filter:curators' }
  ),
  { hideState: true }
);

export default function CuratorsFilter({
  onChange,
  values,
}: Omit<CuratorsFilterListProps, 'accountsQuery'>) {
  return (
    <BaseCuratorsFilter
      onChange={onChange}
      values={values}
      accountsQuery={{
        variables: { accountSearchType: UserSearchAccountTypesEnum.Curator },
      }}
    />
  );
}

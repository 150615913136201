/**
 * @generated SignedSource<<2a05204ff02afd896382bc8720819158>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProvenanceObjectType = "MINT" | "SALE" | "LAZY_MINT" | "LISTING" | "PRODUCT_BID" | "MEDIA_BID" | "PURCHASE" | "TRANSFER";
export type NFTsTagAndProvenanceQuery$variables = {
  productSlug?: string | null;
  digitalMediaId?: number | null;
  isLiveOnChain?: boolean | null;
  first?: number | null;
  orderBy?: string | null;
  password?: string | null;
};
export type NFTsTagAndProvenanceQuery$data = {
  readonly nfts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly printEdition: number;
        readonly provenance: ReadonlyArray<{
          readonly type: ProvenanceObjectType;
          readonly source: string | null;
          readonly dest: string | null;
          readonly amountUsd: number | null;
          readonly amountEth: number | null;
          readonly currency: string | null;
          readonly date: any | null;
          readonly user: {
            readonly fullName: string | null;
            readonly id: string;
            readonly pk: string;
            readonly profileImageUrl: string;
            readonly username: string;
          } | null;
        } | null> | null;
        readonly metadata: {
          readonly id: string;
          readonly pk: string;
          readonly tags: ReadonlyArray<string>;
          readonly title: string;
          readonly totalSupply: number;
        } | null;
        readonly contract: {
          readonly isExternal: boolean | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};
export type NFTsTagAndProvenanceQuery = {
  variables: NFTsTagAndProvenanceQuery$variables;
  response: NFTsTagAndProvenanceQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "digitalMediaId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLiveOnChain"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productSlug"
},
v6 = [
  {
    "kind": "Variable",
    "name": "digitalMediaId",
    "variableName": "digitalMediaId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isLiveOnChain",
    "variableName": "isLiveOnChain"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "productSlug",
    "variableName": "productSlug"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printEdition",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ProvenanceType",
  "kind": "LinkedField",
  "name": "provenance",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountEth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountAbstractType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "NFTMetadataAbstractType",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSupply",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isExternal",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTsTagAndProvenanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTEthereumContractType",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTsTagAndProvenanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTEthereumContractType",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c8f4b0e621444ff667e9e7ef80b65c2",
    "id": null,
    "metadata": {},
    "name": "NFTsTagAndProvenanceQuery",
    "operationKind": "query",
    "text": "query NFTsTagAndProvenanceQuery(\n  $productSlug: String\n  $digitalMediaId: Float\n  $isLiveOnChain: Boolean\n  $first: Int\n  $orderBy: String\n  $password: String\n) {\n  nfts(productSlug: $productSlug, digitalMediaId: $digitalMediaId, first: $first, isLiveOnChain: $isLiveOnChain, orderBy: $orderBy, password: $password) {\n    edges {\n      node {\n        id\n        pk\n        printEdition\n        provenance {\n          type\n          source\n          dest\n          amountUsd\n          amountEth\n          currency\n          date\n          user {\n            fullName\n            id\n            pk\n            profileImageUrl\n            username\n          }\n        }\n        metadata {\n          id\n          pk\n          tags\n          title\n          totalSupply\n        }\n        contract {\n          isExternal\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "064f82bb40cfe596656b6a83424cb8eb";

export default node;

import { useRecoilValue } from 'recoil';
import { graphQLSelector } from 'recoil-relay';

import ConcreteNFTContractRequest from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import { NFTType } from 'types/graphql/NFT';

import RelayEnvironment from '../RelayEnvironment';

type NFT = {
  listing: {
    shouldUseDropSaleContract: NFTType['listing']['shouldUseDropSaleContract'];
    liveSale?: {
      contract?: NFTType['listing']['liveSale']['contract'];
    };
  };
};

export const RecoilSaleContractQuery = graphQLSelector({
  environment: RelayEnvironment,
  key: 'saleContract',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.DigitalMediaSaleCore }),
});

export const RecoilDropSaleContractQuery = graphQLSelector({
  environment: RelayEnvironment,
  key: 'dropSaleContract',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.DropSaleCore }),
});

export function useDigitalMediaSaleCoreV3Contract() {
  return useRecoilValue(RecoilSaleContractQuery);
}

export function useGeneralSaleContract(nft: NFT) {
  // If it's a drop related NFT, use the DropSaleCore contract, otherwise use the Sale contract
  const saleContract = useRecoilValue(RecoilSaleContractQuery);
  const dropSaleCoreContract = useRecoilValue(RecoilDropSaleContractQuery);

  return nft.listing.shouldUseDropSaleContract
    ? dropSaleCoreContract
    : saleContract;
}

export default function useSaleContract(nft: NFT) {
  const generalSaleContract = useGeneralSaleContract(nft);
  // We need to look at the contract address of the live sale since it might be an older DigitalMediaSaleCore
  return nft.listing.liveSale?.contract
    ? nft.listing.liveSale.contract
    : generalSaleContract;
}

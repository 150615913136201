import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import generatePriceString, {
  generateShortEthPriceString,
} from 'utils/currency/generatePricing';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import useActivityGTM from 'utils/GTM/activity';

import { ACTION_TITLES } from '../filters/ActionsFilter';

import * as styles from 'css/pages/activity/ActivityCard.module.css';

export interface ActivityCardBaseProps {
  showCollector?: boolean;
}

export interface ActivityCardProps extends ActivityCardBaseProps {
  artistName: string;
  currencyDisplayMode: CurrencyDisplayMode;
  editionInfo: string;
  imageSrc: string;
  priceEth: number;
  priceUsd: number;
  productSlug: string;
  timeAgo: string;
  title: string;
  type: string;
  className?: string;
  collectorName?: string;
  reloadDocument?: boolean;
}

function ActivityCard({
  imageSrc,
  title,
  artistName,
  collectorName,
  type,
  timeAgo,
  priceEth,
  priceUsd,
  productSlug,
  editionInfo,
  className,
  currencyDisplayMode,
  reloadDocument = true,
  showCollector = false,
}: ActivityCardProps) {
  const isMobile = useIsMobile();
  const track = useActivityGTM();
  const font = isMobile ? MPFonts.textSmallMedium : MPFonts.textNormalMedium;

  const handleClick = useCallback(() => {
    const amount =
      currencyDisplayMode === CurrencyDisplayMode.ETH ? priceEth : priceUsd;

    track.clickTile(
      title,
      artistName,
      type,
      amount ? amount.toString() : '',
      productSlug,
      editionInfo
    );
  }, [
    currencyDisplayMode,
    editionInfo,
    artistName,
    priceEth,
    priceUsd,
    productSlug,
    title,
    type,
    track,
  ]);

  return (
    <Link
      className={joinClasses('invisibleAnchor', styles.activityCardContainer)}
      to={productSlug ? ROUTES.NFT(productSlug) : '#'}
      onClick={handleClick}
      reloadDocument={reloadDocument}
    >
      <div
        className={joinClasses(
          className,
          styles.activityCard,
          styles.activityGrid
        )}
      >
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${imageSrc})` }}
        />
        <div className={joinClasses(font, styles.title)}>
          <span className="ellipsis">{title}</span>
        </div>
        <div className={joinClasses(font, styles.artist)}>
          <span className="ellipsis">
            {isMobile && !showCollector ? (
              <span className={MPColorClass.SolidNeutralGray5}>by&nbsp;</span>
            ) : null}
            {generateFormattedUserFullName(
              showCollector ? collectorName : artistName
            )}
          </span>
        </div>
        <div className={joinClasses(font, styles.edition)}>
          {isMobile ? (
            <div className={MPColorClass.SolidNeutralGray5}>Edition&nbsp;</div>
          ) : null}
          {editionInfo}
        </div>
        <div className={joinClasses(font, styles.transaction)}>
          {ACTION_TITLES[type] || capitalizeFirstLetter(type)}
        </div>
        <div className={joinClasses(font, styles.price)}>
          {currencyDisplayMode === CurrencyDisplayMode.ETH && priceEth ? (
            <div className={joinClasses('ellipsis', styles.combined)}>
              <span>{generateShortEthPriceString(priceEth)}</span>
              {!!priceUsd && (
                <span className={styles.secondary}>
                  {generatePriceString(priceUsd, CurrencyDisplayMode.USD, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                </span>
              )}
            </div>
          ) : currencyDisplayMode === CurrencyDisplayMode.USD && priceUsd ? (
            generatePriceString(priceUsd, currencyDisplayMode, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })
          ) : null}
        </div>
        <div className={joinClasses(font, styles.date)}>{timeAgo}</div>
      </div>
    </Link>
  );
}

export default ActivityCard;

import { useMemo } from 'react';

import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { ModularPagesSectionsQuery } from 'graphql/__generated__/ModularPagesSectionsQuery.graphql';
import { PageMediaVariantEnum } from 'types/__generated__/graphql';

import PreviewPagination, {
  usePreviewPaginationState,
} from 'pages/product/productPreview/PreviewPagination';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { NFTMedia } from 'utils/nftUtils';

import * as styles from 'css/pages/modularPage/sections/ImageGallerySection.module.css';

type ImageGalleryType =
  ModularPagesSectionsQuery['response']['modularPageSections']['edges'][number]['node']['imageGallery'];

interface ImageGallerySectionProps {
  imageGallery: ImageGalleryType;
  className?: string;
}

export default function ImageGallerySection({
  className,
  imageGallery,
}: ImageGallerySectionProps) {
  const medias = useMemo(
    () =>
      imageGallery.galleryImages.map(
        (image) =>
          ({
            hasVideo: false,
            highResUrl: image.imageUrl,
            id: image.id,
            lowResUrl: image.imageUrl,
            mediumResUrl: image.imageUrl,
            rawfileExtension: image.imageUrl.split('.').pop() || 'jpg',
            videoUrl: null,
          } as NFTMedia)
      ),
    [imageGallery.galleryImages]
  );
  const paginationState = usePreviewPaginationState({ medias });

  return (
    imageGallery.galleryImages.length > paginationState.currentIndex && (
      <section
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGap[16],
          className,
          styles.container
        )}
      >
        <div className={styles.slides}>
          {imageGallery.galleryImages.map((image, idx) => (
            <div
              className={joinClasses(
                CSSGlobal.Flex.Col,
                CSSGap[16],
                styles.slide,
                { [styles.current]: paginationState.currentIndex === idx }
              )}
            >
              <div
                className={joinClasses(styles.slideImage, {
                  [styles.hero]:
                    imageGallery.variant === PageMediaVariantEnum.Hero,
                })}
              >
                <img
                  alt={image.caption}
                  key={image.id}
                  loading="lazy"
                  src={image.imageUrl}
                />
              </div>

              {!!image.caption && imageGallery.galleryImages.length === 1 && (
                <div
                  className={joinClasses(
                    MPFonts.paragraphSmall,
                    MPColorClass.SolidNeutralGray5,
                    CSSGlobal.TextAlign.Centered,
                    CSSGlobal.Cursor.Default
                  )}
                >
                  {image.caption}
                </div>
              )}
            </div>
          ))}
        </div>

        <PreviewPagination
          forceThumbnails
          medias={medias}
          {...paginationState}
        />
      </section>
    )
  );
}

import { startTransition, useCallback, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import SessionUpdateMinBidAmountMutationRequest, {
  SessionUpdateMinBidAmountMutation,
} from 'graphql/__generated__/SessionUpdateMinBidAmountMutation.graphql';

import { useHandleSetETH } from 'hooks/pricing/useMax';
import useSession, { useRefreshSession } from 'hooks/useSession';
import { useCachedExchangeRate } from 'hooks/wallet/useCachedCurrentBalance';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';
import { getSafeWeiAmountFromEthUserInput } from 'utils/EthPriceUtils';

import * as styles from 'css/pages/settings/collection/collection/SetMinOffer.module.css';

/* eslint-disable @typescript-eslint/no-shadow */
enum Phase {
  Congratulations = 'Congratulations',
  SetMinOffer = 'SetMinOffer',
}
/* eslint-enable @typescript-eslint/no-shadow */

const TITLE = {
  [Phase.SetMinOffer]: 'Set Minimum Offer',
  [Phase.Congratulations]: 'Minimum Offer',
};

function SetMinOfferPhase({ ethDisplay, usdDisplay }) {
  const formikProps = useFormikContext<{ minOfferETH: string }>();
  const [, handleEthBidAmountUpdate] = useHandleSetETH((value) => {
    formikProps.setFieldValue(
      'minOfferETH',
      typeof value !== 'function'
        ? value
        : value(formikProps.values.minOfferETH)
    );
  });

  return (
    <>
      Set a minimum offer for all of your listed artworks.
      <MPStyledTextField
        className={styles.minOfferTextField}
        name="minOfferETH"
        inputMode="decimal"
        value={formikProps.values.minOfferETH}
        error={undefined}
        onChange={handleEthBidAmountUpdate}
      />
      <div className={CSSGlobal.Flex.RowSpaceBetween}>
        <span className={MPFonts.textNormalMedium}>Total</span>
        <span>
          <span className={MPFonts.price}>{ethDisplay}</span>&nbsp;
          <span
            className={joinClasses(
              MPFonts.subPrice,
              MPColorClass.SolidNeutralGray5
            )}
          >
            {usdDisplay}
          </span>
        </span>
      </div>
    </>
  );
}

function Congratulations({ ethDisplay }) {
  return (
    <>
      You have successfully set a minimum offer of {ethDisplay} for all of your
      listed artworks.
    </>
  );
}

function SetMinOfferForm({ isDialogOpen, isLoading, close, phase }) {
  const session = useSession();
  const isMobile = useIsMobile();
  const formikProps = useFormikContext<{ minOfferETH: string }>();
  const minOfferETH = parseFloat(formikProps.values.minOfferETH || '0');

  const ethDisplay = generatePriceString(minOfferETH, CurrencyDisplayMode.ETH, {
    isMobile,
    useUserCurrency: true,
  });

  const exchangeRate = useCachedExchangeRate();

  const usdDisplay = generatePriceString(
    minOfferETH * exchangeRate,
    CurrencyDisplayMode.USD,
    {
      isMobile,
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    }
  );

  const ActionButtonJSX = {
    [Phase.SetMinOffer]: (
      <MPActionButton
        fullWidth
        size="large"
        isLoading={isLoading}
        onClick={formikProps.submitForm}
        disabled={
          !formikProps.values.minOfferETH ||
          minOfferETH === session.account.store?.minimumBidAmountInEther
        }
      >
        Set Minimum Offer
      </MPActionButton>
    ),
    [Phase.Congratulations]: (
      <MPActionButton fullWidth size="large" onClick={close}>
        Continue
      </MPActionButton>
    ),
  };

  const ContentJSX = {
    [Phase.SetMinOffer]: (
      <SetMinOfferPhase ethDisplay={ethDisplay} usdDisplay={usdDisplay} />
    ),
    [Phase.Congratulations]: <Congratulations ethDisplay={ethDisplay} />,
  };

  return isDialogOpen ? (
    <MPStandardDialog
      title={TITLE[phase]}
      open={isDialogOpen}
      onClose={close}
      actionButton={ActionButtonJSX[phase]}
    >
      {ContentJSX[phase]}
    </MPStandardDialog>
  ) : null;
}

export default function SetMinOffer() {
  const session = useSession();
  const refresh = useRefreshSession();
  const [updateMinBid, isUpdatingMinBid] =
    useMutation<SessionUpdateMinBidAmountMutation>(
      SessionUpdateMinBidAmountMutationRequest
    );
  const [phase, setPhase] = useState(Phase.SetMinOffer);

  const onSubmit = useCallback(
    (values) => {
      updateMinBid({
        onCompleted: (data) => {
          if (data.updateMinBidAmount.success) {
            startTransition(refresh);
            setPhase(Phase.Congratulations);
          }
        },
        variables: {
          requestData: {
            minBidAmount: getSafeWeiAmountFromEthUserInput(values.minOfferETH),
          },
        },
      });
    },
    [updateMinBid, refresh]
  );

  const [isDialogOpen, setDialogOpen] = useState(false);
  const open = useCallback(() => setDialogOpen(true), []);
  const close = useCallback(() => {
    setPhase(Phase.SetMinOffer);
    setDialogOpen(false);
  }, []);

  return (
    <>
      <MPActionButton variant="tertiary" onClick={open}>
        Set&nbsp;Minimum&nbsp;Offer
      </MPActionButton>
      {!!isDialogOpen && (
        <Formik
          initialValues={{
            minOffer: session.account.store?.minimumBidAmountInEther ?? 0,
          }}
          onSubmit={onSubmit}
        >
          <SetMinOfferForm
            isDialogOpen={isDialogOpen}
            isLoading={isUpdatingMinBid}
            close={close}
            phase={phase}
          />
        </Formik>
      )}
    </>
  );
}

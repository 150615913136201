import {
  ChangeEvent,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment from 'moment-timezone';
import {
  CaptionProps,
  DateRange,
  DayContentProps,
  DayPicker,
  useNavigation,
} from 'react-day-picker';
import { useSearchParams } from 'react-router-dom';

import {
  MPAnimations,
  MPChip,
  MPDropdown,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import useStateBackedObjRef from 'hooks/useStateBackedObjRef';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import useActivityGTM from 'utils/GTM/activity';

import * as styles from 'css/pages/activity/ActivityFilters.module.css';

const TITLE = 'Date Range';
const TODAY = moment().toDate();

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
// Selection of range for multiple years by default causes difficulty in initial selection with shorter range
// leave it to user to decide the range.
export const DEFAULT_DATE = {
  FROM: TODAY,
  TO: TODAY,
};

const isValidDateString = (value: string) =>
  /^\d{2}\/\d{2}\/\d{4}$/g.test(value) && !Number.isNaN(+Date.parse(value));

const parseStringToDate = (
  value: string,
  format?: moment.MomentFormatSpecification
) => moment(value, format).toDate();
const formatDateToString = (value: Date | string) =>
  moment(value).format(DEFAULT_DATE_FORMAT);
export const formatDateToQueryString = (value: Date | string) =>
  moment(value).format('YYYY-MM-DD');
const parseQueryStringToDate = (value: string) =>
  moment(value, 'YYYY-MM-DD').toDate();
export const formatDateToChipString = (value: Date | string) =>
  moment(value).format('MMM DD, YYYY');

function CalendarNavigationButton({
  children,
  show = true,
  ...props
}: {
  children: ReactNode;
  show?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={joinClasses(
        MPAnimations.Color.DarkToLight,
        styles.calendarNavigationButton
      )}
      role="button"
      tabIndex={0}
    >
      {show ? children : null}
    </div>
  );
}

function CalendarHead({ displayMonth }: CaptionProps) {
  const { nextMonth, previousMonth: prevMonth, goToMonth } = useNavigation();
  const handlePrevClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!prevMonth) return;

      goToMonth(prevMonth);
    },
    [goToMonth, prevMonth]
  );
  const handleNextClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!nextMonth) return;

      goToMonth(nextMonth);
    },
    [goToMonth, nextMonth]
  );

  return (
    <div
      className={joinClasses(
        CSSGlobal.Flex.RowCenterAlign,
        CSSGlobal.Flex.RowSpaceBetween,
        CSSGap[10],
        CSSMargin.BOTTOM[8],
        styles.calendarHead
      )}
    >
      <CalendarNavigationButton show={!!prevMonth} onClick={handlePrevClick}>
        <ArrowLeftIcon fontSize="19" />
      </CalendarNavigationButton>

      <span
        className={joinClasses(
          MPFonts.textSmallSemiBold,
          CSSGlobal.Cursor.Default
        )}
      >
        {moment(displayMonth).format('MMMM yyyy')}
      </span>

      <CalendarNavigationButton show={!!nextMonth} onClick={handleNextClick}>
        <ArrowRightIcon fontSize="19" />
      </CalendarNavigationButton>
    </div>
  );
}

function CalendarDayContent({ date, activeModifiers }: DayContentProps) {
  return (
    <span
      className={joinClasses({
        [MPFonts.textSmallMedium]: activeModifiers.range_middle,
        [MPFonts.textSmallSemiBold]:
          activeModifiers.range_start || activeModifiers.range_end,
      })}
    >
      {moment(date).format('D')}
    </span>
  );
}

export type DateFilterValue = Partial<{
  from: string;
  to: string;
}>;

interface DateFilterProps {
  onChange: (value: DateFilterValue) => void;
  value: DateFilterValue;
}

export default function DateFilter({ value, onChange }: DateFilterProps) {
  const track = useActivityGTM();
  const [validValue, setValidValue] = useState<DateRange>({
    from: DEFAULT_DATE.FROM,
    to: DEFAULT_DATE.TO,
  });
  const [valueRef, getValue, setValue] = useStateBackedObjRef<{
    from?: string;
    to?: string;
  }>({});
  const [initialValue, setInitialValue] = useState<DateRange>({
    from: null,
    to: null,
  });
  const [dayPickerValue, setDayPickerValue] = useState<DateRange>(validValue);

  useEffect(() => {
    const newValidValue = {
      from: value.from ? parseQueryStringToDate(value.from) : DEFAULT_DATE.FROM,
      to: value.to ? parseQueryStringToDate(value.to) : DEFAULT_DATE.TO,
    };
    setValidValue(newValidValue);
    setDayPickerValue(newValidValue);
    setValue('from', formatDateToString(newValidValue.from));
    setValue('to', formatDateToString(newValidValue.to));

    if (!initialValue.from && !initialValue.to) {
      setInitialValue(newValidValue);
    }
  }, [value.from, value.to, initialValue, setValue]);

  const handleToggle = useCallback(
    (open: boolean) => track.toggleFilter(TITLE, open),
    [track]
  );

  const handleChange = useCallback(
    (newValidValue: DateRange) => {
      setValidValue(newValidValue);
      setDayPickerValue(newValidValue);

      onChange({
        from:
          newValidValue.from !== initialValue.from
            ? formatDateToQueryString(newValidValue.from)
            : '',
        to:
          newValidValue.to !== initialValue.to
            ? formatDateToQueryString(newValidValue.to)
            : '',
      });
    },
    [initialValue, onChange]
  );

  const handleFromValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setValue('from', event.target.value),
    [setValue]
  );

  const handleToValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setValue('to', event.target.value),
    [setValue]
  );

  const handleValueBlur = useCallback(
    (key: keyof DateFilterValue) => {
      if (
        (valueRef.current[key] && !isValidDateString(valueRef.current[key])) ||
        (valueRef.current.from &&
          valueRef.current.to &&
          parseStringToDate(valueRef.current.from) >=
            parseStringToDate(valueRef.current.to))
      ) {
        setValue(key, formatDateToString(validValue[key]));
        return;
      }

      handleChange({
        from: parseStringToDate(valueRef.current.from),
        to: parseStringToDate(valueRef.current.to),
      });
    },
    [valueRef, validValue, setValue, handleChange]
  );

  const handleDayPickerChange = useCallback(
    (range: DateRange) => {
      if (!range) {
        setDayPickerValue(validValue);
      } else if (!range.from || !range.to) {
        setDayPickerValue(range);
      } else {
        handleChange(range);
      }
    },
    [validValue, handleChange]
  );

  return (
    <MPDropdown title={TITLE} onToggle={handleToggle}>
      <div
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGap[16],
          styles.dateFilter
        )}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.Row,
            CSSGlobal.Flex.CenteredRow,
            CSSGap[16]
          )}
        >
          <MPStyledTextField
            label="Start Date"
            placeholder={formatDateToString(DEFAULT_DATE.FROM)}
            value={getValue('from') || ''}
            onChange={handleFromValueChange}
            onBlur={() => handleValueBlur('from')}
            inputMode="text"
            className={styles.input}
          />

          <MPStyledTextField
            label="End Date"
            placeholder={formatDateToString(DEFAULT_DATE.TO)}
            value={getValue('to') || ''}
            onChange={handleToValueChange}
            onBlur={() => handleValueBlur('to')}
            inputMode="text"
            className={styles.input}
          />
        </div>

        <DayPicker
          mode="range"
          className={styles.calendar}
          classNames={{
            cell: styles.calendarTableCell,
            day: joinClasses(MPFonts.textSmallRegular, styles.calendarDay),
            day_disabled: styles.calendarDayDisabled,
            day_range_end: styles.calendarDateRangeEnd,
            day_range_middle: styles.calendarDateRangeMiddle,
            day_range_start: styles.calendarDateRangeStart,
            table: styles.calendarTable,
          }}
          components={{ Caption: CalendarHead, DayContent: CalendarDayContent }}
          selected={dayPickerValue}
          toDate={DEFAULT_DATE.TO}
          hideHead
          defaultMonth={dayPickerValue.to}
          onSelect={handleDayPickerChange}
        />
      </div>
    </MPDropdown>
  );
}

export function useDateFilter(
  urlParam = 'adf'
): [string, string, JSX.Element, JSX.Element, () => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParams = (searchParams.get(urlParam) ?? '').split('_');
  const [state, setState] = useState<{ from?: string; to?: string }>({
    from: moment(urlParams[0]).isValid() ? urlParams[0] : undefined,
    to: moment(urlParams[1]).isValid() ? urlParams[1] : undefined,
  });
  const reset = useCallback(() => setState({}), []);
  const oldDateString = urlParams.join('-');
  const newDateString = state.from ? `${state.from}_${state.to ?? ''}` : '';
  useEffect(() => {
    if (oldDateString !== newDateString) {
      if (newDateString) {
        searchParams.set(urlParam, newDateString);
      } else {
        searchParams.delete(urlParam);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlParam, setSearchParams, searchParams, oldDateString, newDateString]);
  return [
    state.from,
    state.to,
    (!state.from && !state.to) ||
    (state.from === formatDateToQueryString(DEFAULT_DATE.FROM) &&
      state.to ===
        formatDateToQueryString(DEFAULT_DATE.TO)) ? null : state.to ===
        formatDateToQueryString(DEFAULT_DATE.TO) || !state.to ? (
      <MPChip
        label={`From ${formatDateToChipString(state.from)} to now`}
        onDelete={reset}
      />
    ) : (
      <MPChip
        label={`From ${formatDateToChipString(
          state.from
        )} to ${formatDateToChipString(state.to)}`}
        onDelete={reset}
      />
    ),
    <DateFilter
      value={state}
      onChange={(activityDate) => setState({ ...activityDate })}
    />,
    reset,
  ];
}

import { MPDivider } from '@mp-frontend/core-components';

import CSSGap from 'types/enums/css/Gap';

import Section from '../common/Section';
import LogoutSubSection from './LogoutSubSection';
import PasswordSubSection from './PasswordSubSection';
import TwoFactorSubSection from './TwoFactorSubSection';

export default function SecuritySection({
  hideTitle,
}: {
  hideTitle?: boolean;
}) {
  return (
    <>
      <Section
        title={hideTitle ? null : 'Security'}
        contentClassName={CSSGap[24]}
      >
        <PasswordSubSection />
        <MPDivider />

        <TwoFactorSubSection />
        <MPDivider />

        <LogoutSubSection />
      </Section>
    </>
  );
}

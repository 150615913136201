import { forwardRef, useCallback, useEffect, useRef } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/tabs/PillTabs.module.css';

interface PillTabProps {
  isSelected: boolean;
  label: string;
  onSelect: (value: string) => void;
  value: string;
}

const PillTab = forwardRef<HTMLButtonElement, PillTabProps>(
  ({ label, value, isSelected, onSelect }, ref) => {
    const handleClick = useCallback(() => onSelect(value), [value, onSelect]);

    return (
      <button
        ref={ref}
        className={joinClasses(MPFonts.textSmallSemiBold, styles.tab, {
          [styles.selected]: isSelected,
        })}
        type="button"
        onClick={handleClick}
      >
        {label}
      </button>
    );
  }
);

export interface PillTabsProps<T extends string> {
  config: Partial<Record<T, string>>;
  onChange: (value: T) => void;
  selectedValue: T;
}

export default function PillTabs<T extends string>({
  config,
  selectedValue,
  onChange,
}: PillTabsProps<T>) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: string]: HTMLButtonElement }>({});

  const scrollToPosition = useCallback(
    (container: HTMLDivElement, position: number) =>
      container.scrollTo({ behavior: 'smooth', left: position }),
    []
  );

  useEffect(() => {
    if (!scrollRef.current || !selectedValue) return;
    const container = scrollRef.current;
    const selectedButton = tabRefs.current[selectedValue];

    if (!selectedButton) return;

    const { left: containerLeft } = container.getBoundingClientRect();
    const { left: selectedTabLeft } = selectedButton.getBoundingClientRect();
    const { scrollWidth, clientWidth, scrollLeft } = container;
    const selectedXPosition = selectedTabLeft - containerLeft;
    const nearEndThreshold = 100;

    if (selectedXPosition > clientWidth - nearEndThreshold) {
      scrollToPosition(
        container,
        Math.min(
          scrollLeft + (clientWidth - nearEndThreshold),
          scrollWidth - clientWidth
        )
      );
    } else if (selectedXPosition < nearEndThreshold) {
      scrollToPosition(
        container,
        Math.max(scrollLeft - (clientWidth - nearEndThreshold), 0)
      );
    }
  }, [scrollToPosition, scrollRef, selectedValue]);

  return (
    <div className={joinClasses(styles.container)}>
      <div
        className={joinClasses(CSSGlobal.NoScrollbar, styles.innerContainer)}
        ref={scrollRef}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.CenteredRow,
            CSSGlobal.Flex.NoWrap,
            CSSGap[16],
            styles.title
          )}
        >
          {Object.entries(config).map(([value, label]) => (
            <PillTab
              key={value}
              label={label as string}
              value={value}
              isSelected={selectedValue === value}
              onSelect={onChange}
              ref={(el) => {
                tabRefs.current[value] = el;
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

import { CSSProperties, MutableRefObject, useMemo, useRef } from 'react';
import { usePaginationFragment, usePreloadedQuery } from 'react-relay';
import { useOutletContext } from 'react-router-dom';
import { Variables } from 'relay-runtime';

import {
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ArtworkCard from 'components/cards/ArtworkCard';
import { DefaultLoader } from 'components/DefaultSuspense';
import useInfiniteQueryScroll from 'hooks/useInfiniteQueryScroll';
import CSSGlobal from 'types/enums/css/Global';

import * as gridStyles from 'css/components/grid/ThreeItemGrid.module.css';

type Resp = {
  ' $fragmentSpreads': unknown;
  readonly ' $data'?: { results: { edges: Readonly<any> } };
};

interface GridOperationType<C extends Readonly<Resp>> {
  readonly response: C;
  readonly variables: Variables;
  readonly rawResponse?: unknown | undefined;
}

export default function SettingsGrid<
  K extends Readonly<Resp>,
  Q extends GridOperationType<K>
>({ queryRef, fragmentRequest, paginatedRequest }) {
  const isMobile = useIsMobile();
  const visibilityRef = useRef(null);
  const { scrollRef: outletScrollRef } = useOutletContext<{
    scrollRef: MutableRefObject<Element>;
  }>();
  const backupScrollRef = useRef(null);
  const { data, loading } = useInfiniteQueryScroll({
    pageSize: 6,
    paginatedQueryResults: usePaginationFragment<Q, K>(
      fragmentRequest,
      usePreloadedQuery<Q>(paginatedRequest, queryRef)
    ),
    ref: visibilityRef,
    scrollRef: outletScrollRef || backupScrollRef,
  });

  const rows = Math.ceil(data.length / (isMobile ? 1 : 3));
  const style = useMemo(
    () => ({
      '--rows': rows,
    }),
    [rows]
  ) as CSSProperties;

  return (
    <div>
      <div
        ref={backupScrollRef}
        className={joinClasses(gridStyles.grid, gridStyles.gridMinHeight)}
        style={style}
      >
        {data.map((nft) => (
          <ArtworkCard nft={nft} key={nft.id} />
        ))}
        {!data.length && (
          <>
            <div />
            <div
              className={joinClasses(
                MPFonts.paragraphNormal,
                MPColorClass.SolidNeutralGray5,
                gridStyles.noResults
              )}
            >
              No results found. Consider removing or broadening filters.
            </div>
          </>
        )}
      </div>
      {!!loading && <DefaultLoader className={CSSGlobal.Flex.CenteredRow} />}
      <div ref={visibilityRef}>&nbsp;</div>
    </div>
  );
}

import {
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {
  MPBackgroundColorClass,
  MPColorValue,
  MPDivider,
  MPIconButton,
} from '@mp-frontend/core-components';
import { RemoveIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import PillTabs, { PillTabsProps } from 'components/tabs/PillTabs';
import { TrackingContext } from 'components/trackingContext';
import { EcommerceSourceType } from 'GTM';
import CSSGlobal from 'types/enums/css/Global';

import ExploreArtists from '../artists';
import { ArtistsFiltersState } from '../artists/Filters';
import ExploreArtworks from '../artworks';
import { ArtworksFiltersState } from '../artworks/Filters';
import ExploreEditorials from '../editorials';
import { EditorialsFiltersState } from '../editorials/Filters';
import ExploreExhibitions from '../exhibitions';
import { ExhibitionsFiltersState } from '../exhibitions/Filters';
import { ExploreType, FiltersStateType } from '../types';

import * as styles from 'css/pages/explore/dialog/index.module.css';

const PILLS_TABS_CONFIG: Partial<Record<ExploreType, string>> = {
  [ExploreType.Artworks]: 'Artworks',
  [ExploreType.Exhibitions]: 'Exhibitions',
  [ExploreType.Artists]: 'Artists',
  [ExploreType.Editorial]: 'Editorial',
};

interface ExploreDialogProps {
  exploreType: PillTabsProps<ExploreType>['selectedValue'];
  filters: FiltersStateType;
  onClose: () => void;
  onFiltersChange: (value: Partial<FiltersStateType>) => void;
  onFiltersReset: () => void;
  onTypeChange: PillTabsProps<ExploreType>['onChange'];
  hideHero?: boolean;
}

export default function ExploreDialog({
  exploreType,
  filters,
  hideHero,
  onClose,
  onFiltersChange,
  onFiltersReset,
  onTypeChange,
}: ExploreDialogProps) {
  return (
    <Dialog
      fullScreen
      open
      sx={{
        [`& .${dialogClasses.paper}`]: {
          backgroundColor: MPColorValue.BackgroundDefault,
          padding: 0,
        },
        zIndex: 9,
      }}
      onClose={onClose}
    >
      <DialogTitle
        className={joinClasses(
          CSSGlobal.Flex.RowSpaceBetween,
          CSSGlobal.Flex.Centered,
          styles.dialogTitle
        )}
      >
        <PillTabs
          config={PILLS_TABS_CONFIG}
          onChange={onTypeChange}
          selectedValue={exploreType}
        />
        <MPIconButton
          className={MPBackgroundColorClass.CommonWhite}
          onClick={onClose}
        >
          <RemoveIcon alt="Close Dialog" />
        </MPIconButton>
      </DialogTitle>
      <MPDivider className={styles.dialogDivider} />
      <DialogContent className={styles.dialogContent}>
        <TrackingContext source={EcommerceSourceType.Explore}>
          {{
            [ExploreType.Artists]: (
              <ExploreArtists
                hideHero={hideHero}
                filters={filters as ArtistsFiltersState}
                onFiltersChange={onFiltersChange}
                onFiltersReset={onFiltersReset}
              />
            ),
            [ExploreType.Artworks]: (
              <ExploreArtworks
                hideHero={hideHero}
                filters={filters as ArtworksFiltersState}
                onFiltersChange={onFiltersChange}
                onFiltersReset={onFiltersReset}
              />
            ),
            [ExploreType.Editorial]: (
              <ExploreEditorials
                hideHero={hideHero}
                filters={filters as EditorialsFiltersState}
                onFiltersChange={onFiltersChange}
                onFiltersReset={onFiltersReset}
              />
            ),
            [ExploreType.Exhibitions]: (
              <ExploreExhibitions
                hideHero={hideHero}
                filters={filters as ExhibitionsFiltersState}
                onFiltersChange={onFiltersChange}
                onFiltersReset={onFiltersReset}
              />
            ),
          }[exploreType] ?? null}
        </TrackingContext>
      </DialogContent>
    </Dialog>
  );
}

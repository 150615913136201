/** TODO(brian): unify this with the other settings page: https://app.asana.com/0/1208694122360326/1208771012775006/f **/
.page {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.threeItemGridMinHeight {
  --three-item-card-height: var(--artworkCard-desktopHeight);
}

@media screen and (min-width: 1920px) {
  .page {
    max-width: 1760px;
  }

  .threeItemGridMinHeight {
    --three-item-card-height: var(--artworkCard-monitorHeight);
  }
}

@media (--mq-is-mobile) {
  .threeItemGridMinHeight {
    --three-item-card-height: var(--artworkCard-mobileHeight);
  }
}

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { MPActionButton } from '@mp-frontend/core-components';

import CSSGlobal from 'types/enums/css/Global';

interface ToggleFilterProps {
  isSelected: boolean;
  onToggle: () => void;
  title: string;
}

function ToggleFilter({ isSelected, onToggle, title }: ToggleFilterProps) {
  return (
    <MPActionButton
      variant={isSelected ? 'tertiary-inverted' : 'tertiary'}
      className={CSSGlobal.Width.MaxContent}
      onClick={onToggle}
    >
      {title}
    </MPActionButton>
  );
}

export function useToggleFilter(
  title: string,
  urlParam?: string
): [JSX.Element, boolean, Dispatch<SetStateAction<boolean>>] {
  const [searchParams, setSearchParams] = useSearchParams();
  const isUrlSelected = urlParam ? !!searchParams.get(urlParam) : false;
  const [isSelected, setSelected] = useState<boolean>(isUrlSelected);
  const toggle = useCallback(() => setSelected((prev) => !prev), []);
  useEffect(() => {
    if (!!urlParam && isUrlSelected !== isSelected) {
      if (isSelected) {
        searchParams.set(urlParam, '1');
      } else {
        searchParams.delete(urlParam);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlParam, setSearchParams, searchParams, isUrlSelected, isSelected]);
  return [
    <ToggleFilter isSelected={isSelected} title={title} onToggle={toggle} />,
    isSelected,
    setSelected,
  ];
}

export default ToggleFilter;

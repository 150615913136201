import { ReactNode, Ref } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import getProfileImage from 'utils/getProfileImage';
import { HexString } from 'utils/jwt/walletUtils';

import * as styles from 'css/pages/product/ProductRankedAuction.module.css';

export type LeaderboardRowData = {
  isOwn?: boolean;
  user?: {
    fullName?: string;
    profileImageUrl?: string;
    username?: string;
  };
  userAddress?: HexString;
};

export function LeaderboardUserCell({
  isOwn = false,
  user,
  userAddress,
}: LeaderboardRowData) {
  const profileImage = (
    <div
      className={styles.leaderboardCollectorProfileImage}
      style={{
        backgroundImage: `url(${getProfileImage(user?.profileImageUrl)})`,
      }}
    />
  );

  const profileUsername = (
    <div className={joinClasses('ellipsis', styles.leaderboardCollectorName)}>
      {generateFormattedUserFullName(user?.fullName ?? userAddress)}
    </div>
  );

  return (
    <div className={styles.leaderboardCollector}>
      {isOwn || !user.username ? (
        profileImage
      ) : (
        <a href={ROUTES.PROFILE.SHOP(user.username)} className="defaultLink">
          {profileImage}
        </a>
      )}

      {isOwn || !user.username ? (
        profileUsername
      ) : (
        <a href={ROUTES.PROFILE.SHOP(user.username)} className="defaultLink">
          {profileUsername}
        </a>
      )}
    </div>
  );
}

export function LeaderboardPriceCell({
  priceInEther,
  priceInUsd,
  primary = CurrencyDisplayMode.ETH,
}: {
  priceInEther: string;
  priceInUsd: string;
  primary?: CurrencyDisplayMode;
}) {
  const [primaryPrice, secondaryPrice] =
    primary === CurrencyDisplayMode.ETH
      ? [priceInEther, priceInUsd]
      : [priceInUsd, priceInEther];
  return (
    <div
      className={joinClasses(MPFonts.textSmallMedium, styles.leaderboardPrice)}
    >
      <span className={styles.primary}>{primaryPrice}</span>
      <span>{secondaryPrice}</span>
    </div>
  );
}

export interface LeaderboardRowProps<TData extends LeaderboardRowData> {
  className: string;
  columns: string[];
  data: TData;
  index: number;
  renderCells: (data: TData, index: number) => ReactNode[];
  rowRef?: Ref<HTMLTableRowElement>;
}

function LeaderboardRow<TData extends LeaderboardRowData>({
  className,
  columns,
  index,
  data,
  rowRef,
  renderCells,
}: LeaderboardRowProps<TData>) {
  const cells = renderCells(data, index).slice(0, columns.length);

  return (
    <tr ref={rowRef} className={className}>
      {cells.map((cell, idx) => (
        <td key={columns[idx]} className={MPFonts.textSmallMedium}>
          {cell}
        </td>
      ))}
    </tr>
  );
}

export default LeaderboardRow;

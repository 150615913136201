.container {
  --gap: 32px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 var(--gap) 0 var(--gap);
}

.grid {
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  align-content: flex-start;
  position: relative;
  gap: 16px;
  transition: height 200ms ease-in-out;
}

.gridMinHeight {
  min-height: calc(1 * var(--three-item-card-height, 589px) + 32px);
}

.noResults {
  text-align: center;
  margin: 40px auto 0;
}

@media screen and (min-width: 1920px) {
  .container {
    max-width: 1760px;
  }
}

@media (--mq-is-mobile) {
  .container {
    --gap: 24px;
    padding: 0;
  }

  .grid {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

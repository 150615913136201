import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { SearchIcon } from '../../icons';
import MPChip from './MPChip';

import * as styles from '../css/chips/MPChipInlineSearchInput.module.css';

export interface MPChipInlineSearchInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  placeholder?: string;
  variant?: 'filled' | 'outlined';
}

export default function MPChipInlineSearchInput({
  value,
  variant,
  placeholder,
  ...props
}: MPChipInlineSearchInputProps) {
  const [hiddenTextNode, setHiddenTextNode] = useRefState<HTMLSpanElement>();
  const [width, setWidth] = useState(20);
  useEffect(() => {
    const newWidth = hiddenTextNode?.getBoundingClientRect().width;
    setWidth(newWidth ?? 0);
  }, [value, hiddenTextNode]);

  const style = useMemo(
    () => ({
      flexBasis: `${Math.floor((width ?? 0) + 6)}px`,
      width: `${Math.floor((width ?? 0) + 6)}px`,
    }),
    [width]
  );

  return (
    <span className={styles.container}>
      <MPChip
        variant={variant ?? 'outlined'}
        icon={
          <>
            <SearchIcon fontSize="small" />
            <input
              placeholder={placeholder}
              style={style}
              className={joinClasses(MPFonts.buttonMedium, styles.textInput)}
              value={value}
              {...props}
            />
          </>
        }
      />
      <span
        className={joinClasses(MPFonts.buttonMedium, styles.hiddenTextNode)}
        ref={setHiddenTextNode}
      >
        {value}
      </span>
    </span>
  );
}

const THRESHOLDS = {
  AUDIENCE: 100,
  COLLECTED_ARTISTS: 0,
  COLLECTED_ARTWORKS: 0,
  COLLECTORS: 1,
  CREATED_ARTWORKS: 1,
  CURATED_EXHIBITIONS: 0,
  EXHIBITIONS: 0,
  TOTAL_SALES: 1000,
} as const;

export default THRESHOLDS;

/**
 * @generated SignedSource<<ac1e3e6f491726cad4912826d7ddb2bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityPaginatedFragment$data = {
  readonly results: {
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string | null;
        readonly transactionUrl: string | null;
        readonly action: string | null;
        readonly createdAt: string | null;
        readonly priceUsd: number | null;
        readonly priceEth: number | null;
        readonly isEthTransaction: boolean | null;
        readonly fromUser: {
          readonly id: string;
          readonly pk: string;
          readonly username: string;
          readonly fullName: string | null;
        } | null;
        readonly toUser: {
          readonly id: string;
          readonly pk: string;
          readonly username: string;
          readonly fullName: string | null;
        } | null;
        readonly digitalMediaRelease: {
          readonly id: string;
          readonly pk: string;
          readonly printEdition: number;
          readonly metadata: {
            readonly id: string;
            readonly pk: string;
            readonly thumbnailImage: string | null;
            readonly title: string;
            readonly totalSupply: number;
            readonly author: {
              readonly pk: string;
              readonly id: string;
              readonly fullName: string | null;
            } | null;
          } | null;
          readonly listing: {
            readonly id: string;
            readonly pk: string;
            readonly productSlug: string;
          } | null;
        } | null;
      } | null;
      readonly cursor: string;
    } | null>;
  } | null;
  readonly " $fragmentType": "ActivityPaginatedFragment";
};
export type ActivityPaginatedFragment$key = {
  readonly " $data"?: ActivityPaginatedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityPaginatedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "results"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "actions"
    },
    {
      "kind": "RootArgument",
      "name": "activityDate"
    },
    {
      "kind": "RootArgument",
      "name": "collectors"
    },
    {
      "kind": "RootArgument",
      "name": "curators"
    },
    {
      "kind": "RootArgument",
      "name": "currencyMode"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "isActivityPage"
    },
    {
      "kind": "RootArgument",
      "name": "isShowFollowing"
    },
    {
      "kind": "RootArgument",
      "name": "price"
    },
    {
      "kind": "RootArgument",
      "name": "showFollowingCollectors"
    },
    {
      "kind": "RootArgument",
      "name": "sortOrder"
    },
    {
      "kind": "RootArgument",
      "name": "sortType"
    },
    {
      "kind": "RootArgument",
      "name": "storeCollectionSlug"
    },
    {
      "kind": "RootArgument",
      "name": "userNames"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PaginatedActivityNFTsQuery.graphql')
    }
  },
  "name": "ActivityPaginatedFragment",
  "selections": [
    {
      "alias": "results",
      "args": [
        {
          "kind": "Variable",
          "name": "actions",
          "variableName": "actions"
        },
        {
          "kind": "Variable",
          "name": "activityDate",
          "variableName": "activityDate"
        },
        {
          "kind": "Variable",
          "name": "collectors",
          "variableName": "collectors"
        },
        {
          "kind": "Variable",
          "name": "curators",
          "variableName": "curators"
        },
        {
          "kind": "Variable",
          "name": "currencyMode",
          "variableName": "currencyMode"
        },
        {
          "kind": "Variable",
          "name": "isActivityPage",
          "variableName": "isActivityPage"
        },
        {
          "kind": "Variable",
          "name": "isShowFollowing",
          "variableName": "isShowFollowing"
        },
        {
          "kind": "Variable",
          "name": "price",
          "variableName": "price"
        },
        {
          "kind": "Variable",
          "name": "showFollowingCollectors",
          "variableName": "showFollowingCollectors"
        },
        {
          "kind": "Variable",
          "name": "sortOrder",
          "variableName": "sortOrder"
        },
        {
          "kind": "Variable",
          "name": "sortType",
          "variableName": "sortType"
        },
        {
          "kind": "Variable",
          "name": "storeCollectionSlug",
          "variableName": "storeCollectionSlug"
        },
        {
          "kind": "Variable",
          "name": "userNames",
          "variableName": "userNames"
        }
      ],
      "concreteType": "ActivityConnection",
      "kind": "LinkedField",
      "name": "__PaginatedActivityNFTsQuery__results_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ActivityType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "transactionUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "action",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceUsd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceEth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isEthTransaction",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountAbstractType",
                  "kind": "LinkedField",
                  "name": "fromUser",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountAbstractType",
                  "kind": "LinkedField",
                  "name": "toUser",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NFTAbstractType",
                  "kind": "LinkedField",
                  "name": "digitalMediaRelease",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "printEdition",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTMetadataAbstractType",
                      "kind": "LinkedField",
                      "name": "metadata",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thumbnailImage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalSupply",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountAbstractType",
                          "kind": "LinkedField",
                          "name": "author",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v1/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTListingAbstractType",
                      "kind": "LinkedField",
                      "name": "listing",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productSlug",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cda1c7f2ac0b878ffd9ccb64679a8ec8";

export default node;

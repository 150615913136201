import { useGateValue } from '@statsig/react-bindings';

import useSession from 'hooks/useSession';

import useSetDocTitle from '../useSetDocTitle';
import CreditCard from './creditCard';
import Wallets from './wallets';
import Withdrawals from './withdrawals';

export default function WalletPageSplitter() {
  useSetDocTitle('Wallets & Withdrawals');
  useSession().contracts.preLoad();

  const showNewCollectionDesign = useGateValue('gate_settings_v3_collection');

  return showNewCollectionDesign ? (
    <Wallets hideTitle />
  ) : (
    <>
      <CreditCard stripeCardQuery={{ variables: {} }} />
      <Wallets />
      <Withdrawals />
    </>
  );
}

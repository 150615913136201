import {
  AccountAbstractType,
  NftAbstractType,
  NftMediaMetadataType,
  NftMetadataAbstractType,
  NotificationActionEnum,
} from 'types/__generated__/graphql';

import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { ListingType } from 'types/graphql/Listing';
import emptyFunc from 'utils/emptyFunc';
import getURLParamFromCurrentURL from 'utils/getURLParamFromCurrentURL';
import { LoginSignUpModalType } from 'utils/hocs/withLoginRequiredClick';

import Session from './Session';

export enum AuthenticationMethod {
  CoinbaseWallet = 'coinbasewallet',
  Email = 'email',
  MetaMask = 'metamask',
  WalletConnect = 'walletconnect',
}

export function createGATrackingQueryString(
  config: Record<string, string>
): string {
  return Object.entries(config)
    .reduce((memo, [key, value]) => {
      if (value) {
        memo.push(`${key}=${value}`);
      }
      return memo;
    }, [])
    .join('|');
}

export type FilterValueType =
  | string
  | boolean
  | Array<string>
  | Record<string, string | boolean | string[]>
  | undefined;

export function convertFilterValueString(
  obj: Record<string, FilterValueType> = {}
): string {
  return Object.entries(obj)
    .filter(([, value]) => value !== undefined)
    .map(([, value]) => {
      if (value === undefined) {
        return '';
      }
      if (Array.isArray(value)) {
        return value.join(',');
      }
      if (typeof value === 'object') {
        return Object.entries(value)
          .filter(([, val]) => val !== undefined)
          .map(([key, val]) => `${key}=${val}`)
          .join('&');
      }
      return String(value);
    })
    .join('&');
}

// Request is that all empty strings be turned into nulls, to facilitate GTM setting of default values.
function recursivelySetEmptyStringsToNull(obj: Record<string, any>) {
  if (Array.isArray(obj)) {
    return obj.map((item) => recursivelySetEmptyStringsToNull(item));
  }

  const res = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] != null) {
      res[key] = recursivelySetEmptyStringsToNull(obj[key]);
    } else if (obj[key] === '') {
      res[key] = null;
    } else {
      res[key] = obj[key];
    }
  });
  return res;
}

declare global {
  /* eslint-disable vars-on-top */
  /* eslint-disable no-var */
  var dataLayer: Array<Record<string, any>>;
}

window.dataLayer = window.dataLayer || [];

/* eslint-disable camelcase */
declare global {
  interface Window {
    analytics_vars: {
      login_type: string;
      user_id: string;
    };
  }
}
/* eslint-enable camelcase */

window.analytics_vars = window.analytics_vars || {
  login_type: null,
  user_id: null,
};

async function genGlobalParams() {
  const session = await Session.awaitSessionData();
  return {
    _clear: true,
    dl_source: 'mp',
    login_type: session.lastLogin,
    user_id: session.account?.pk,
  };
}

async function tagPush(event, obj) {
  const baseParams = await genGlobalParams();
  window.dataLayer.push({
    event,
    ...baseParams,
    ...recursivelySetEmptyStringsToNull(obj),
  });
}

/* eslint-disable camelcase */
interface EcommerceItem {
  item_brand: string;
  item_id: number;
  item_name: string;
  item_variant: string; // float, current eth equivalent
  quantity: number;
  item_category1?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  payment_type?: string;
  price?: number;
  // float
  price_ether?: number;
}

export enum EcommercePaymentType {
  CreditCard = 'credit card',
  ETH = 'eth',
}

export enum EcommercePurchaseType {
  Offer = 'offer',
  Purchase = 'purchase',
}

export enum EcommerceSourceType {
  DropPage = 'drop page',
  Explore = 'explore page',
  ExploreArtworksPage = 'explore artworks page',
  HomePage = 'home page',
  ModularPage = 'modular page',
  Navigation = 'nav',
  OpenEditionPage = 'open edition page',
  ProductPage = 'product page',
  ProfilePage = 'profile page',
  Search = 'search page',
}

export interface Ecommerce {
  coupon?: string;
  currency?: number;
  current_ether_value?: number;
  offer_or_purchase?: EcommercePurchaseType;
  payment_type?: EcommercePaymentType;
  tax?: string;
  total_order_count?: number;
  transaction_id?: string;
  value?: string;
  value_ether?: number;
  wallet_type?: string;
}
/* eslint-enable camelcase */

export enum NotificationCategoryType {
  Primary = 'primary',
  Secondary = 'secondary', // anything that can be muted (likes, follows, comments)
}

export enum DropClickEventType {
  ActivityCreatorClick = 'Drop Activity Creator Click',
  DropMoreInfoModalVideoPlayer = 'Drop More Info Modal Video Player',
  DropMoreLink = 'Drop More Link',
  DropsActivityViewAll = 'Drops Activity View All',
  MoreAboutThisDrop = 'More About This Drop',
  PreSaleEligibilityCheck = 'Drop Pre-Sale Eligibility Check',
  SubscribeForUpdates = 'Subscribe for updates',
}

type SimplifiedNFTType = Pick<NftAbstractType, 'printEdition' | 'pk'> & {
  listing: Pick<
    ListingType,
    | 'productSlug'
    | 'reservePriceInUsd'
    | 'lowestAskInUsd'
    | 'reservePriceInEth'
    | 'lowestAskInEth'
  > & {
    liveBid: Pick<ListingType['liveBid'], 'bidInUsd' | 'bidInEther'> | null;
  };
  metadata: Pick<
    NftMetadataAbstractType,
    'rawfileExtension' | 'title' | 'totalSupply'
  > & {
    author: Pick<AccountAbstractType, 'fullName' | 'username'>;

    mediaMetadata: Pick<
      NftMediaMetadataType,
      'filesize' | 'height' | 'width'
    > | null;
  };
};

const GTM = {
  ecommerce: (() => {
    const generateItem = function generateEcommerceItem(
      nft: SimplifiedNFTType,
      item: Partial<EcommerceItem> = {},
      itemListId = '',
      genre = '',
      source: EcommerceSourceType = EcommerceSourceType.ProductPage
    ) {
      const ecommerceItem = {
        // must be int
        item_brand: nft.metadata.author.username,

        item_category2: `edition=${nft.printEdition}/${nft.metadata.totalSupply}`,
        item_category3: genre || getURLParamFromCurrentURL('genre') || 'None',
        item_category4:
          itemListId || getURLParamFromCurrentURL('carousel') || 'None',

        item_id: nft.pk,

        item_name: nft.listing.productSlug,

        item_variant: `${nft.metadata.mediaMetadata.width} x ${nft.metadata.mediaMetadata.height} px, ${nft.metadata.rawfileExtension} (${nft.metadata.mediaMetadata.filesize})`,
        price:
          nft.listing?.liveBid?.bidInUsd ||
          nft.listing?.reservePriceInUsd ||
          nft.listing?.lowestAskInUsd,
        price_ether:
          nft.listing?.liveBid?.bidInEther ||
          nft.listing?.reservePriceInEth ||
          nft.listing?.lowestAskInEth,
        quantity: 1,
        source,
        ...item,
      };

      if (itemListId) {
        return {
          ...ecommerceItem,
          item_list_id: itemListId,
          item_list_name: itemListId,
        };
      }

      return ecommerceItem;
    };
    const generateObj = function generateEcommerceParams(
      nft: SimplifiedNFTType,
      item: Partial<EcommerceItem> = {},
      ecommerce: Partial<Ecommerce> = {},
      itemListId = '',
      genre = '',
      source: EcommerceSourceType = EcommerceSourceType.ProductPage
    ) {
      const obj = {
        ecommerce: {
          currency: 'USD',
          total_order_count: 1,
          ...ecommerce,
          items: [generateItem(nft, item, itemListId, genre, source)],
        },
      };
      obj.ecommerce.total_order_count = obj.ecommerce.items.reduce(
        (prev, curr) => prev + curr.quantity,
        0
      );
      return obj;
    };

    const generateOpenEditionEcommerceObj = (
      title: string,
      amountInETH: number,
      amountInUSD: number,
      id: number,
      username: string,
      count: number,
      source: EcommerceSourceType,
      currency: CurrencyDisplayMode
    ) => {
      const obj = {
        ecommerce: {
          currency,
          items: [
            {
              item_brand: username,
              item_category2: 'None',
              item_category3: 'None',
              item_category4: 'None',
              item_id: id,
              item_name: title,
              price: amountInUSD,
              price_ether: amountInETH,
              quantity: count,
              source,
            },
          ],
          total_order_count: count,
        },
      };
      return obj;
    };

    return {
      /* eslint-disable camelcase */
      addPaymentInfo(
        nft: SimplifiedNFTType,
        ecommerce: {
          payment_type: EcommercePaymentType;
        }
      ) {
        return tagPush('view_item', generateObj(nft, {}, ecommerce));
      },
      beginCheckout(
        nft: SimplifiedNFTType,
        data: {
          offer_or_purchase: EcommercePurchaseType;
        },
        source: EcommerceSourceType = EcommerceSourceType.ProductPage
      ) {
        return tagPush(
          'BEGIN_CHECKOUT',
          generateObj(nft, {}, data, '', '', source)
        );
      },
      error(
        errorMessage: string,
        data: { offer_or_purchase: EcommercePurchaseType }
      ) {
        tagPush('ERROR', {
          detail: createGATrackingQueryString({
            error_message: `<${errorMessage}>`,
            ...data,
          }),
        });
      },
      purchase(
        nft: SimplifiedNFTType,
        data: {
          offer_or_purchase: EcommercePurchaseType;
          payment_type: EcommercePaymentType;
          total_order_count: number;
          value: string;
          value_ether: number;
          coupon?: string;
          tax?: string;
          transaction_id?: string;
          wallet_type?: string;
        },
        source: EcommerceSourceType = EcommerceSourceType.ProductPage
      ) {
        return tagPush('PURCHASE', generateObj(nft, {}, data, '', '', source));
      },
      purchaseOpenEdition(
        title: string,
        amountInETH: number,
        amountInUSD: number,
        id: number,
        username: string,
        count: number,
        source: EcommerceSourceType,
        currency: CurrencyDisplayMode
      ) {
        return tagPush(
          'PURCHASE',
          generateOpenEditionEcommerceObj(
            title,
            amountInETH,
            amountInUSD,
            id,
            username,
            count,
            source,
            currency
          )
        );
      },
      trackSelectItem(nft: SimplifiedNFTType, itemListId = '', genre = '') {
        return tagPush('select_item', {
          ecommerce: {
            item_list_id: itemListId,
            item_list_name: itemListId,
            items: [generateItem(nft, {}, itemListId, genre)],
          },
        });
      },
      trackViewItem(nft: SimplifiedNFTType, ecommerce, itemListId = '') {
        return tagPush('view_item', {
          detail: `carousel=${itemListId}`,
          ...generateObj(nft, ecommerce, {}, itemListId),
        });
      },
      trackViewItemList(nfts: SimplifiedNFTType[], itemListId: string) {
        return tagPush('view_item_list', {
          ecommerce: {
            item_list_id: itemListId,
            item_list_name: itemListId,
            items: nfts.map((nft) => generateItem(nft, {}, itemListId)),
          },
        });
      },
      /* eslint-enable camelcase */
    };
  })(),

  /* eslint-enable camelcase */
  explore: {
    trackCarouselPaddleClick(direction: 'left' | 'right') {
      return tagPush('Exhibit Paddle Click', {
        detail: `direction=<${direction}>`,
      });
    },
    trackFiltersApplied() {
      let queryFilters = window.location.search;
      if (queryFilters[0] === '?') queryFilters = queryFilters.slice(1);
      queryFilters = queryFilters.replace(/&/g, '|');

      return tagPush('Filters Applied', {
        detail: queryFilters,
      });
    },
    trackOpenFilterBox() {
      return tagPush('Open Filter Box', {});
    },
    trackSortByChange(sortBy: string) {
      return tagPush('Sort By Change', {
        detail: sortBy,
      });
    },
  },

  fullScreenOverlay: (name: string) => ({
    async click(): Promise<void> {
      return tagPush(`Overlay Click`, {
        detail: `overlay=${name}`,
      });
    },
    async close(): Promise<void> {
      return tagPush(`Overlay Close Click`, {
        detail: `overlay=${name}`,
      });
    },
    async open(): Promise<void> {
      return tagPush(`Overlay Pop Up Impression`, {
        detail: `overlay=${name}`,
      });
    },
  }),

  // Custom Page View Event, maps to the start of the session.
  initPageView: (userId?: string, lastLogin?: string) => {
    tagPush('mp_page_view', {});
    // Run Once
    GTM.initPageView = emptyFunc;
    window.analytics_vars.user_id = userId ?? null;
    window.analytics_vars.login_type = lastLogin ?? null;
  },

  loginModal: {
    trackConnectWallet(type: AuthenticationMethod) {
      return tagPush('login_modal_connect_wallet', {
        login_type: type,
        method: type,
      });
    },
    trackForgotPasswordClicked() {
      return tagPush('login_modal_forgot_password_clicked', {});
    },
    trackLoginSuccessful(type: AuthenticationMethod) {
      return tagPush('login', {
        login_type: type,
        method: type,
      });
    },
    trackOpenModal() {
      return tagPush('login_modal_open', {});
    },
    trackSignUpSuccessful(type: AuthenticationMethod) {
      return tagPush('sign_up', {
        login_type: type,
        method: type,
      });
    },
    trackStart2FA() {
      return tagPush('login_modal_start_2fa', {});
    },
    trackStartSignUp(type: AuthenticationMethod) {
      return tagPush('login_modal_start_signup', {
        login_type: type,
        method: type,
      });
    },
    trackSwitchModalType(type: LoginSignUpModalType) {
      return tagPush('login_modal_switch_type', {
        login_type: type,
        method: type,
      });
    },
  },
  notification: {
    async trackCommentToggle(on: boolean) {
      return tagPush('Notification - Comment Toggle', {
        detail: `toggle=${on ? 'on' : 'off'}`,
      });
    },
    async trackDrawerToggle(open: boolean, count?: number) {
      return tagPush('Notification Window', {
        detail: `window=${open ? 'open' : 'close'}|count=${count}`,
      });
    },
    async trackFollowToggle(on: boolean) {
      return tagPush('Notification - Follow Toggle', {
        detail: `toggle=${on ? 'on' : 'off'}`,
      });
    },
    async trackLikeToggle(on: boolean) {
      return tagPush('Notification - Like Toggle', {
        detail: `toggle=${on ? 'on' : 'off'}`,
      });
    },
    async trackNotificationClick(
      category: NotificationCategoryType,
      type: NotificationActionEnum
    ) {
      return tagPush('Notification Click', {
        detail: `category=${category}`,
        type,
      });
    },
    /* eslint-enable camelcase */
  },
  pdp: {
    async trackClickEventDescriptionsType(
      description: string,
      source: EcommerceSourceType,
      dropTitle?: string
    ) {
      return tagPush('Click Descriptions Tab', {
        detail: createGATrackingQueryString({
          dropTitle,
          source,
          tab: description,
        }),
      });
    },
    async trackClickEventProductTitleType(
      source: EcommerceSourceType,
      dropTitle?: string
    ) {
      return tagPush('Click Title', {
        detail: createGATrackingQueryString({
          dropTitle,
          source,
        }),
      });
    },
    async trackClickExhibitionType(
      source: EcommerceSourceType,
      dropSlug: string,
      dropTitle: string
    ) {
      return tagPush('Click Exhibition Module', {
        detail: createGATrackingQueryString({
          dropSlug,
          dropTitle,
          source,
        }),
      });
    },
    async trackClickGenerateSampleType(
      source: EcommerceSourceType,
      dropTitle: string
    ) {
      return tagPush('Click Generate Sample', {
        detail: createGATrackingQueryString({
          dropTitle,
          source,
        }),
      });
    },
    async trackClickLeaderboardType(
      source: EcommerceSourceType,
      dropTitle?: string
    ) {
      return tagPush('Click Leaderboard', {
        detail: createGATrackingQueryString({
          dropTitle,
          source,
        }),
      });
    },
    async trackOpenSeaCtaClick(productSlug: string) {
      return tagPush('Click OpenSea Cta', {
        detail: createGATrackingQueryString({
          productSlug,
        }),
      });
    },
    trackRelatedCardClick(position: number, nft_id: string) {
      return tagPush('Click Related Card', {
        detail: `position=${position}|nft_id=${nft_id}`,
      });
    },
  },
  profile: {
    trackActivitySortByChange(sortBy: string) {
      return tagPush('Activity - Sort By Change', {
        detail: sortBy,
      });
    },
  },
  social: {
    async trackComment(nftId: number) {
      return tagPush('comment', {
        detail: `item=${nftId}`,
      });
    },

    async trackFollow(globalSlug: string, follow: boolean) {
      return tagPush(follow ? 'follow' : 'unfollow', {
        detail: `creator_name=${globalSlug}`,
      });
    },

    async trackLike(like: boolean, nftId: number, details = {}) {
      let detail = `item=${nftId}`;

      if (details) {
        Object.entries(details).forEach(([key, value]) => {
          detail += `|${key}=${value}`;
        });
      }

      return tagPush(like ? 'like' : 'unlike', {
        detail,
      });
    },

    async trackShare(number: string | number, shareMethod: string) {
      return tagPush('share', {
        detail: `item=${number}|share_method=${shareMethod}`,
      });
    },

    /* eslint-enable camelcase */
  },
};

export default GTM;

import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import { MPColorClass } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import DefaultSuspense from 'components/DefaultSuspense';
import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { PageWithFooter } from 'components/Footer';
import PillTabs from 'components/tabs/PillTabs';
import CSSGlobal from 'types/enums/css/Global';

import { PAGESLUG } from '../pageSlug';

import * as styles from 'css/pages/settings/wallet/index.module.css';

const PILLS_TABS_CONFIG = {
  [PAGESLUG.account]: 'Personal Information',
  [PAGESLUG.security]: 'Security',
};

export default function AccountSettingsPage() {
  const showNewCollectionDesign = useGateValue('gate_settings_v3_collection');
  const pageSlug = useLocation()
    .pathname.split('/')
    .filter((a) => a)
    .at(-1);
  const navigate = useNavigate();
  const navigateToPage = useCallback(
    (slug) =>
      navigate(
        slug === PAGESLUG.account
          ? '/settings/account'
          : `/settings/account/${slug}`
      ),
    [navigate]
  );
  return (
    <>
      {!!showNewCollectionDesign && (
        <PillTabs
          config={PILLS_TABS_CONFIG}
          onChange={navigateToPage}
          selectedValue={PAGESLUG[pageSlug]}
        />
      )}
      <DefaultSuspense>
        <PageErrorBoundary>
          <PageWithFooter>
            <div
              className={joinClasses(
                MPColorClass.CommonBlack,
                CSSGlobal.Cursor.Default,
                styles.walletPage
              )}
            >
              <Outlet />
            </div>
          </PageWithFooter>
        </PageErrorBoundary>
      </DefaultSuspense>
    </>
  );
}

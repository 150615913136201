/**
 * @generated SignedSource<<ea4d7f0f38918d9bdd2231a204bb0d42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NFTCommentMutation$variables = {
  nftMetadataId: number;
  nftId: number;
  body: string;
  parentId?: number | null;
  replyToUser?: number | null;
};
export type NFTCommentMutation$data = {
  readonly commentNft: {
    readonly success: boolean | null;
    readonly comment: {
      readonly pk: string;
      readonly id: string;
      readonly body: string;
      readonly createdAt: any;
      readonly parentId: number | null;
      readonly threadCount: number;
      readonly user: {
        readonly id: string;
        readonly pk: string;
        readonly fullName: string | null;
        readonly profileImageUrl: string;
        readonly username: string;
      } | null;
      readonly replyToUser: {
        readonly id: string;
        readonly pk: string;
        readonly fullName: string | null;
        readonly profileImageUrl: string;
        readonly username: string;
      } | null;
    } | null;
  } | null;
};
export type NFTCommentMutation = {
  variables: NFTCommentMutation$variables;
  response: NFTCommentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nftId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nftMetadataId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "replyToUser"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "profileImageUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  }
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "body",
            "variableName": "body"
          },
          {
            "kind": "Variable",
            "name": "nftId",
            "variableName": "nftId"
          },
          {
            "kind": "Variable",
            "name": "nftMetadataId",
            "variableName": "nftMetadataId"
          },
          {
            "kind": "Variable",
            "name": "parentId",
            "variableName": "parentId"
          },
          {
            "kind": "Variable",
            "name": "replyToUser",
            "variableName": "replyToUser"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "CommentNFTMutation",
    "kind": "LinkedField",
    "name": "commentNft",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NFTCommentType",
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "threadCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountAbstractType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountAbstractType",
            "kind": "LinkedField",
            "name": "replyToUser",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTCommentMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTCommentMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "0fddbef33166bf12c0bc7b167e4911c1",
    "id": null,
    "metadata": {},
    "name": "NFTCommentMutation",
    "operationKind": "mutation",
    "text": "mutation NFTCommentMutation(\n  $nftMetadataId: Int!\n  $nftId: Int!\n  $body: String!\n  $parentId: Int\n  $replyToUser: Int\n) {\n  commentNft(requestData: {nftMetadataId: $nftMetadataId, nftId: $nftId, body: $body, parentId: $parentId, replyToUser: $replyToUser}) {\n    success\n    comment {\n      pk\n      id\n      body\n      createdAt\n      parentId\n      threadCount\n      user {\n        id\n        pk\n        fullName\n        profileImageUrl\n        username\n      }\n      replyToUser {\n        id\n        pk\n        fullName\n        profileImageUrl\n        username\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5af18831874ae4288fde030957b2ee4";

export default node;

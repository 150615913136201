import { useCallback, useMemo, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import DefaultSuspense from 'components/DefaultSuspense';
import PillTabs from 'components/tabs/PillTabs';

import { PAGESLUG } from '../pageSlug';

import * as styles from 'css/pages/settings/common.module.css';

const PILLS_TABS_CONFIG = {
  [PAGESLUG.collection]: 'Collection',
  [PAGESLUG.activity]: 'Activity',
  [PAGESLUG.saved]: 'Saved Artworks',
};

export default function CollectionPage() {
  const pageSlug = useLocation()
    .pathname.split('/')
    .filter((a) => a)
    .at(-1);
  const navigate = useNavigate();
  const navigateToPage = useCallback(
    (slug) =>
      navigate(
        slug === PAGESLUG.collection
          ? '/settings/collection'
          : `/settings/collection/${slug}`
      ),
    [navigate]
  );
  const scrollRef = useRef(null);
  const outletContext = useMemo(() => ({ scrollRef }), [scrollRef]);

  return (
    <div className={styles.containerWithPill}>
      <div className={styles.pills}>
        <PillTabs
          config={PILLS_TABS_CONFIG}
          onChange={navigateToPage}
          selectedValue={PAGESLUG[pageSlug]}
        />
      </div>
      <div className={styles.subsectionWithoutPill} ref={scrollRef}>
        <DefaultSuspense>
          <Outlet context={outletContext} />
        </DefaultSuspense>
      </div>
    </div>
  );
}

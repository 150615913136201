/**
 * @generated SignedSource<<91e57982643dbd571cd9c84cee804536>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserAccountType = "CREATOR" | "CONSUMER" | "ANON" | "DOWNGRADED_CREATOR";
export type SessionUserInformationMutation$variables = {};
export type SessionUserInformationMutation$data = {
  readonly session: {
    readonly coinbaseOauthUrl: string;
    readonly contractNetwork: number | null;
    readonly contractNetworkUrl: string | null;
    readonly environment: string | null;
    readonly lastLogin: string | null;
    readonly paypalOauthUrl: string;
    readonly sentrySdkDsn: string | null;
    readonly statsigClientKey: string | null;
    readonly account: {
      readonly id: string;
      readonly pk: string;
      readonly accountType: UserAccountType;
      readonly didCompleteOnboard: boolean | null;
      readonly email: string | null;
      readonly fullName: string | null;
      readonly isAnonAccount: boolean;
      readonly landingUrl: string | null;
      readonly numSessions: number | null;
      readonly payoutEthBalance: number | null;
      readonly payoutUsdBalance: number | null;
      readonly phoneNumber: string | null;
      readonly profileImageUrl: string;
      readonly royaltyEthBalance: number | null;
      readonly royaltyUsdBalance: number | null;
      readonly twoFactorEmailEnabled: boolean;
      readonly twoFactorSmsEnabled: boolean;
      readonly username: string;
      readonly contractApproval: ReadonlyArray<{
        readonly id: string;
        readonly pk: string;
        readonly address: string;
        readonly approved: boolean;
        readonly deleted: boolean;
        readonly operatorContractAddress: `0x${string}` | null;
        readonly approvalContractAddress: `0x${string}` | null;
        readonly canRevoke: boolean | null;
      } | null> | null;
      readonly ethPayoutWallet: {
        readonly id: string;
        readonly coinbaseCustomerId: string | null;
        readonly coinbaseData: string | null;
        readonly address: string;
      } | null;
      readonly paypalPayoutWallet: {
        readonly id: string;
        readonly customerId: string;
        readonly disabled: boolean;
      } | null;
      readonly store: {
        readonly aboutBanner: string | null;
        readonly minimumBidAmountInEther: number | null;
        readonly minimumBidAmountInUsd: number | null;
      } | null;
      readonly wallets: ReadonlyArray<{
        readonly address: `0x${string}`;
        readonly canDisconnect: boolean;
        readonly isCreatorWallet: boolean;
        readonly isCustodialCreatorWallet: boolean;
        readonly isSelectedMintingWallet: boolean;
      }>;
    } | null;
  } | null;
};
export type SessionUserInformationMutation = {
  variables: SessionUserInformationMutation$variables;
  response: SessionUserInformationMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coinbaseOauthUrl",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNetwork",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNetworkUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environment",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastLogin",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paypalOauthUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentrySdkDsn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statsigClientKey",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "didCompleteOnboard",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAnonAccount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "landingUrl",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numSessions",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payoutEthBalance",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payoutUsdBalance",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "royaltyEthBalance",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "royaltyUsdBalance",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "twoFactorEmailEnabled",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "twoFactorSmsEnabled",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "ApprovalType",
  "kind": "LinkedField",
  "name": "contractApproval",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v26/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorContractAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalContractAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canRevoke",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "EthPayoutType",
  "kind": "LinkedField",
  "name": "ethPayoutWallet",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coinbaseCustomerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coinbaseData",
      "storageKey": null
    },
    (v26/*: any*/)
  ],
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "PaypalPayoutType",
  "kind": "LinkedField",
  "name": "paypalPayoutWallet",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aboutBanner",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumBidAmountInEther",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimumBidAmountInUsd",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "WalletAbstractType",
  "kind": "LinkedField",
  "name": "wallets",
  "plural": true,
  "selections": [
    (v26/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canDisconnect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCreatorWallet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCustodialCreatorWallet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSelectedMintingWallet",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionUserInformationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionMutation",
        "kind": "LinkedField",
        "name": "session",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountSessionType",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreTypeV3",
                "kind": "LinkedField",
                "name": "store",
                "plural": false,
                "selections": [
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/)
                ],
                "storageKey": null
              },
              (v33/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionUserInformationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionMutation",
        "kind": "LinkedField",
        "name": "session",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountSessionType",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreTypeV3",
                "kind": "LinkedField",
                "name": "store",
                "plural": false,
                "selections": [
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v33/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61254d75355e6d9a2660944705950895",
    "id": null,
    "metadata": {},
    "name": "SessionUserInformationMutation",
    "operationKind": "mutation",
    "text": "mutation SessionUserInformationMutation {\n  session {\n    coinbaseOauthUrl\n    contractNetwork\n    contractNetworkUrl\n    environment\n    lastLogin\n    paypalOauthUrl\n    sentrySdkDsn\n    statsigClientKey\n    account {\n      id\n      pk\n      accountType\n      didCompleteOnboard\n      email\n      fullName\n      isAnonAccount\n      landingUrl\n      numSessions\n      payoutEthBalance\n      payoutUsdBalance\n      phoneNumber\n      profileImageUrl\n      royaltyEthBalance\n      royaltyUsdBalance\n      twoFactorEmailEnabled\n      twoFactorSmsEnabled\n      username\n      contractApproval {\n        id\n        pk\n        address\n        approved\n        deleted\n        operatorContractAddress\n        approvalContractAddress\n        canRevoke\n      }\n      ethPayoutWallet {\n        id\n        coinbaseCustomerId\n        coinbaseData\n        address\n      }\n      paypalPayoutWallet {\n        id\n        customerId\n        disabled\n      }\n      store {\n        aboutBanner\n        minimumBidAmountInEther\n        minimumBidAmountInUsd\n        id\n      }\n      wallets {\n        address\n        canDisconnect\n        isCreatorWallet\n        isCustodialCreatorWallet\n        isSelectedMintingWallet\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dedbef09c6897d9696f7e6fff464135b";

export default node;

import numberWithCommas from './numberWithCommas';

export default function toNumericShorthand(num: number): string {
  if (!num) return '0';
  if (num < 1) {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(3);
  }
  if (num < 1000) {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  }
  if (num < 100000) {
    return num.toLocaleString('en-US', { maximumFractionDigits: 0 });
  }
  if (num < 999500) {
    // if number is less than 1M, round to the nearest thousand (numbers, between 999.5k and !M get bumped to next section)
    return `${numberWithCommas(Math.round((num + Number.EPSILON) / 1000))}k`;
  }
  return `${numberWithCommas(
    Math.round((num + Number.EPSILON) / 100000) / 10
  )}M`;
}

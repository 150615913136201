import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import DefaultSuspense from 'components/DefaultSuspense';
import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { PageWithFooter } from 'components/Footer';
import PillTabs from 'components/tabs/PillTabs';

import { PAGESLUG } from '../pageSlug';

import * as styles from 'css/pages/settings/wallet/index.module.css';

const PILLS_TABS_CONFIG = {
  [PAGESLUG.creditCards]: 'Credit Cards',
  [PAGESLUG.wallets]: 'Digital Wallets',
  [PAGESLUG.withdrawal]: 'Withdrawal',
};

export default function WalletsPage() {
  const showNewCollectionDesign = useGateValue('gate_settings_v3_collection');
  const pageSlug = useLocation()
    .pathname.split('/')
    .filter((a) => a)
    .at(-1);
  const navigate = useNavigate();
  const navigateToPage = useCallback(
    (slug) =>
      navigate(
        slug === PAGESLUG.wallets
          ? '/settings/wallets'
          : `/settings/wallets/${slug}`
      ),
    [navigate]
  );
  return (
    <>
      {!!showNewCollectionDesign && (
        <PillTabs
          config={PILLS_TABS_CONFIG}
          onChange={navigateToPage}
          selectedValue={PAGESLUG[pageSlug]}
        />
      )}
      <DefaultSuspense>
        <PageErrorBoundary>
          <PageWithFooter>
            <div className={styles.walletPage}>
              <Outlet />
            </div>
          </PageWithFooter>
        </PageErrorBoundary>
      </DefaultSuspense>
    </>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MPChip, MPDropdownSelect } from '@mp-frontend/core-components';

import { ActivityActionEnum } from 'types/__generated__/graphql';

const ACTIONS = [
  ActivityActionEnum.Sold,
  ActivityActionEnum.Purchase,
  ActivityActionEnum.ReceiveBid,
  ActivityActionEnum.PlaceBid,
  ActivityActionEnum.RejectBid,
  ActivityActionEnum.Listing,
  ActivityActionEnum.Transfer,
] as const;

const ACTION_TITLES = {
  [ActivityActionEnum.Sold.toLowerCase()]: 'Sold',
  [ActivityActionEnum.Purchase.toLowerCase()]: 'Purchased',
  [ActivityActionEnum.ReceiveBid.toLowerCase()]: 'Bids & Offers Received',
  [ActivityActionEnum.PlaceBid.toLowerCase()]: 'Bids & Offers Placed',
  [ActivityActionEnum.RejectBid.toLowerCase()]: 'Bids & Offers Rejected',
  [ActivityActionEnum.Listing.toLowerCase()]: 'Listing',
  [ActivityActionEnum.Transfer.toLowerCase()]: 'Transfers',
} as const;

function getActionTitle(title: string) {
  return ACTION_TITLES[title.toLowerCase()];
}

const ACTION_CONFIG = Object.fromEntries(
  ACTIONS.map((action) => [action, getActionTitle(action)])
);

interface TransactionsFilterProps {
  onChange: (values: ActivityActionEnum[]) => void;
  values: ActivityActionEnum[];
}

export default function TransactionsFilter({
  values,
  onChange,
}: TransactionsFilterProps) {
  return (
    <MPDropdownSelect
      title="Transactions"
      config={ACTION_CONFIG}
      values={values}
      onChange={onChange}
      placement="bottom-end"
    />
  );
}

export function useTransactionsFilter(
  urlParam = 'te'
): [Array<ActivityActionEnum>, JSX.Element, JSX.Element, () => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParams = (searchParams.get(urlParam) ?? '')
    .split('-')
    .filter((action) => !!getActionTitle(action));
  const [actions, setActions] = useState(
    urlParams as Array<ActivityActionEnum>
  );
  const reset = useCallback(() => setActions([]), []);
  const oldString = urlParams.join('-');
  const newString = actions.join('-');
  useEffect(() => {
    if (oldString !== newString) {
      if (newString) {
        searchParams.set(urlParam, newString);
      } else {
        searchParams.delete(urlParam);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlParam, setSearchParams, searchParams, oldString, newString]);
  return [
    actions,
    <>
      {actions.map((action) => (
        <MPChip
          key={action}
          label={getActionTitle(action)}
          onDelete={() =>
            setActions((prev) => prev.filter((a) => a !== action))
          }
        />
      ))}
    </>,
    <MPDropdownSelect
      title="Transactions"
      config={ACTION_CONFIG}
      values={actions}
      onChange={setActions as (values: string[]) => void}
      placement="bottom-end"
    />,
    reset,
  ];
}

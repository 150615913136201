.container {
  position: relative;
  max-width: 80vw;
}

.container > :global(.MuiChip-root) {
  border-color: var(--color-commonBlack);
  color: var(--color-commonBlack);
}

.textInput {
  background: transparent;
  border: none;
  min-width: 140px;
  outline: none;
}

.hiddenTextNode {
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  white-space: pre;
}

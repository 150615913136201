import { useCallback, useMemo, useState } from 'react';
import { sepolia } from 'viem/chains';
import { useGateValue } from '@statsig/react-bindings';

import { useIsMobile } from '@mp-frontend/core-components';

import { GM_STUDIO_GENERATOR } from 'constants/ExternalUrls';
import useSession from 'hooks/useSession';

/**
 * GM Studio suggested to use resolution=150 on mobile for performance reasons,
 * otherwise it will render 5k-8k images
 *
 * Also, we need to use a different resolution for desktop for open editions
 * with high resolution generator to avoid the browser crashing
 */
const RESOLUTION: Record<'MOBILE' | 'DESKTOP', Record<string, number>> = {
  DESKTOP: {
    default: null,
    exposures: 500,
  },
  MOBILE: {
    default: 150,
    exposures: 360,
  },
};

export function useUniqueLiveRenderUrl(
  baseUrl: string,
  tokenId: number = null
) {
  const hideOnMobile = useGateValue('gate_hide_generate_sample_on_mobile');
  const [uniqueId, setUniqueId] = useState<number>(0);
  const isMobile = useIsMobile();

  const url = useMemo(() => {
    if (!baseUrl) return null;

    const openEditionSlug = baseUrl
      .match(/collections\/([^/]+)\/live-render/)?.[1]
      ?.toString()
      ?.toLowerCase();

    if (isMobile && hideOnMobile) return null;

    const qs = new URLSearchParams({ u: uniqueId.toString() });

    const deviceType = isMobile ? 'MOBILE' : 'DESKTOP';
    const resolution =
      RESOLUTION[deviceType][openEditionSlug] ?? RESOLUTION[deviceType].default;
    if (resolution) {
      qs.set('res', resolution.toString());
    }

    if (tokenId !== null && tokenId !== undefined) {
      qs.set('tokenId', tokenId.toString());
    }

    return `${baseUrl}?${qs}`;
  }, [baseUrl, tokenId, uniqueId, isMobile, hideOnMobile]);

  const next = useCallback(() => setUniqueId((prev) => prev + 1), []);

  return [url, next] as const;
}

export default function useSeedLiveRenderUrl(
  openEditionSlug: string,
  tokenSeed: 'random' | string
) {
  const session = useSession();

  return openEditionSlug && tokenSeed
    ? `${GM_STUDIO_GENERATOR}/${
        session.contractNetwork === sepolia.id ? sepolia.name : 'mainnet'
      }/collections/${openEditionSlug}/live-render/seeds/${tokenSeed}`
    : null;
}

/**
 * @generated SignedSource<<06da69089298996b46710e487ee76126>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProvenanceObjectType = "MINT" | "SALE" | "LAZY_MINT" | "LISTING" | "PRODUCT_BID" | "MEDIA_BID" | "PURCHASE" | "TRANSFER";
export type NFTsMultiProvenanceQuery$variables = {
  nftMetadataId: number;
};
export type NFTsMultiProvenanceQuery$data = {
  readonly nftMultiEditionProvenance: ReadonlyArray<{
    readonly type: ProvenanceObjectType;
    readonly source: string | null;
    readonly dest: string | null;
    readonly edition: number | null;
    readonly amountUsd: number | null;
    readonly amountEth: number | null;
    readonly currency: string | null;
    readonly date: any | null;
    readonly productSlug: string | null;
    readonly user: {
      readonly fullName: string | null;
      readonly id: string;
      readonly pk: string;
      readonly profileImageUrl: string;
      readonly username: string;
    } | null;
  } | null> | null;
};
export type NFTsMultiProvenanceQuery = {
  variables: NFTsMultiProvenanceQuery$variables;
  response: NFTsMultiProvenanceQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nftMetadataId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "nftMetadataId",
        "variableName": "nftMetadataId"
      }
    ],
    "concreteType": "ProvenanceMultiType",
    "kind": "LinkedField",
    "name": "nftMultiEditionProvenance",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "source",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dest",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "edition",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amountUsd",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amountEth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productSlug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountAbstractType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTsMultiProvenanceQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTsMultiProvenanceQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d26b6713a353d428f94dc3661ab11919",
    "id": null,
    "metadata": {},
    "name": "NFTsMultiProvenanceQuery",
    "operationKind": "query",
    "text": "query NFTsMultiProvenanceQuery(\n  $nftMetadataId: Int!\n) {\n  nftMultiEditionProvenance(nftMetadataId: $nftMetadataId) {\n    type\n    source\n    dest\n    edition\n    amountUsd\n    amountEth\n    currency\n    date\n    productSlug\n    user {\n      fullName\n      id\n      pk\n      profileImageUrl\n      username\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1befa6ff8647813f0a5c8af1853fb735";

export default node;

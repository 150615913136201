:root {
  --artworkCard-desktopHeight: 589px;
  --artworkCard-monitorHeight: 700px;
  --artworkCard-mobileHeight: 480px;
}

.artworkCardSecondaryText {
  color: var(--color-SolidNeutralGray5);
}

.artworkCardContainer {
  background: var(--color-commonWhite);
  box-sizing: border-box;
  gap: 32px;

  @media (--mq-is-mobile) {
    gap: 44px;
  }

  &.centered {
    justify-content: center;
  }

  &.inverted {
    background: var(--color-commonBlack);
    color: var(--color-commonWhite);

    .artworkCardSecondaryText {
      color: var(--color-SolidNeutralGray1);
    }
  }

  @media (hover: hover) {
    &.interactable > .artworkAssetContainer .artworkAsset:hover {
      transform: scale(1.02);
    }
  }
}

.artworkAssetContainer {
  &.medium {
    height: var(--artworkCard-assetCustomDesktopHeight, 365px);

    @media screen and (min-width: 1920px) {
      height: var(--artworkCard-assetCustomMonitorHeight, 465px);
    }

    @media (--mq-is-mobile) {
      height: var(--artworkCard-assetCustomMobileHeight, 309px);
    }
  }

  &.large {
    height: var(--artworkCard-assetCustomDesktopHeight, 430px);

    @media screen and (min-width: 1920px) {
      height: var(--artworkCard-assetCustomMonitorHeight, 529px);
    }

    @media (--mq-is-mobile) {
      height: var(--artworkCard-assetCustomMobileHeight, 363px);
    }
  }
}

.artworkAsset {
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.16));
  transition: transform 300ms ease-out;

  &.artworkAssetMedia {
    max-width: 100%;
    max-height: 100%;
  }

  &.artworkAssetEmbedded {
    height: 100%;
    object-fit: contain;
    width: 100%;

    @media (--mq-is-mobile) {
      min-height: unset;
    }
  }
}

.titleSection {
  flex-grow: 1;
  flex-basis: 0;
}

.tagSection {
  width: max-content;
}

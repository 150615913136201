import { ChangeEvent, useCallback, useState } from 'react';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  MPInlineTextLinkButton,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import { useForceStatsigSessionReplayRecording } from 'components/StatsigProvider';
import { APP_NAME } from 'constants/Utils';
import MPGraphQLError from 'errors/MPGraphQLError';
import { CuratorDialog } from 'pages/product/ProductPrivateCurator';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/private/PasswordPage.module.css';

function PasswordPage({
  curatorFullName,
  curatorUsername,
  isInvalidPassword,
  password,
  onSubmit,
}: {
  isInvalidPassword: boolean;
  onSubmit: (password: string) => void;
  password: string;
  curatorFullName?: string;
  curatorUsername?: string;
}) {
  useForceStatsigSessionReplayRecording();

  const [passwordValue, setPassword] = useState<string>(password);
  const [invalid, setInvalid] = useState<boolean>(isInvalidPassword);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInvalid(false);
      setPassword(event.currentTarget.value);
    },
    [setPassword]
  );

  const handleSubmit = useCallback(
    () => onSubmit(passwordValue),
    [passwordValue, onSubmit]
  );

  const handleKeyPress = useOnEnterKey(handleSubmit);

  return (
    <div
      className={joinClasses(styles.protectedPage, CSSGlobal.Flex.CenteredCol)}
    >
      <div
        className={joinClasses(
          MPColorClass.CommonBlack,
          CSSGlobal.Cursor.Default,
          CSSGlobal.Flex.Col,
          CSSGap[16],
          styles.protectedForm
        )}
      >
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.headline4
          )}
        >
          Welcome to Your Private Viewing Room
        </div>
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.paragraphSmall
          )}
        >
          Please enter the code sent by the {APP_NAME} Concierge team.
        </div>
        <MPStyledTextField
          placeholder="Enter your code"
          value={passwordValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          type="password"
          error={
            invalid ? 'You have entered an invalid entry code.' : undefined
          }
        />
        <MPActionButton disabled={!passwordValue} onClick={handleSubmit}>
          Continue
        </MPActionButton>
        <div
          className={joinClasses(
            CSSGlobal.Flex.InlineRow,
            CSSGlobal.Flex.JustifyCenter,
            MPColorClass.SolidNeutralGray5
          )}
        >
          Didn&apos;t receive a code?&nbsp;
          {curatorFullName && curatorUsername ? (
            <CuratorDialog
              fullName={curatorFullName}
              username={curatorUsername}
            >
              {({ open }) => (
                <MPInlineTextLinkButton onClick={open}>
                  Get in touch
                </MPInlineTextLinkButton>
              )}
            </CuratorDialog>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export type ErrorType = (MPGraphQLError | Error) & {
  additionalData?: {
    curatorFullName?: string;
    curatorUsername?: string;
  };
};

export default PasswordPage;

import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useBalance } from 'wagmi';

import { ETH_TO_USD_EXCHANGE_RATE_URL } from 'constants/ExternalUrls';

import useCachedAccount from './useCachedAccount';

let ethToUsdPromise = null;

async function getEthToUsdExchangeRate(): Promise<number> {
  try {
    if (ethToUsdPromise) return ethToUsdPromise;
    const res = await fetch(ETH_TO_USD_EXCHANGE_RATE_URL);
    const json = await res.json();
    return json.data.rates.USD;
  } catch (_ignoredError) {
    return 0;
  } finally {
    ethToUsdPromise = null;
  }
}

const exchangeRateBalanceAtom = atom<number>({
  default: 0,
  key: 'wallet-cached-exchange-rate',
});

const lastSeenExchangeRateBalanceAtom = atom<number>({
  default: 0,
  key: 'wallet-cached-exchange-last-seen',
});

const TIME_BEFORE_UPDATE = 1000 * 60 * 5;

const ethAtom = atom<string>({
  default: undefined,
  key: 'wallet-cached-balance',
});

let updateExchangeRatePromise;

export function useCachedExchangeRate() {
  const [ethToUsdExchangeRate, setEthToUsdExchangeRate] = useRecoilState(
    exchangeRateBalanceAtom
  );
  const [lastSeen, setLastSeen] = useRecoilState(
    lastSeenExchangeRateBalanceAtom
  );
  useEffect(() => {
    const updateExchangeRate = async (): Promise<void> => {
      ethToUsdPromise = getEthToUsdExchangeRate();
      setEthToUsdExchangeRate(await ethToUsdPromise);
    };
    if (
      (ethToUsdExchangeRate === 0 ||
        Date.now() - lastSeen > TIME_BEFORE_UPDATE) &&
      !updateExchangeRatePromise
    ) {
      updateExchangeRatePromise = updateExchangeRate().finally(() => {
        updateExchangeRatePromise = null;
      });
      setLastSeen(Date.now());
    }
  }, [setEthToUsdExchangeRate, ethToUsdExchangeRate, lastSeen, setLastSeen]);

  return ethToUsdExchangeRate;
}

export default function useCurrentBalance() {
  const { address } = useCachedAccount();
  const [ethToUsdExchangeRate, setEthToUsdExchangeRate] = useRecoilState(
    exchangeRateBalanceAtom
  );
  const [lastSeen, setLastSeen] = useRecoilState(
    lastSeenExchangeRateBalanceAtom
  );
  const [eth, setEth] = useRecoilState(ethAtom);

  const balance = useBalance({
    address,
  });

  useEffect(() => {
    if (balance?.data?.formatted) {
      setEth(balance?.data?.formatted);
    }
    const updateExchangeRate = async (): Promise<void> => {
      ethToUsdPromise = getEthToUsdExchangeRate();
      setEthToUsdExchangeRate(await ethToUsdPromise);
    };
    if (
      (ethToUsdExchangeRate === 0 ||
        Date.now() - lastSeen > TIME_BEFORE_UPDATE) &&
      !updateExchangeRatePromise
    ) {
      updateExchangeRatePromise = updateExchangeRate().finally(() => {
        updateExchangeRatePromise = null;
      });
      setLastSeen(Date.now());
    }
  }, [
    setEth,
    setEthToUsdExchangeRate,
    ethToUsdExchangeRate,
    balance?.data?.formatted,
    lastSeen,
    setLastSeen,
  ]);

  return {
    eth: eth ?? '0',
    isReady: !!address && ethToUsdExchangeRate > 0 && eth !== undefined,
    usd: (parseFloat(eth) * ethToUsdExchangeRate).toFixed(2),
  };
}

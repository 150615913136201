/**
 * @generated SignedSource<<1d973ee71acd1151097db1d60d99a294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type DropPhaseEnum = "LIVE" | "NEW" | "UPCOMING" | "PRESALE" | "SOLD_OUT";
import { FragmentRefs } from "relay-runtime";
export type ExploreExhibitionsPaginatedFragment$data = {
  readonly exhibition: {
    readonly results: {
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly creators: ReadonlyArray<{
            readonly biography: string | null;
            readonly followerCount: number;
            readonly fullName: string | null;
            readonly id: string;
            readonly pk: string;
            readonly profileImageUrl: string;
            readonly totalVolume: {
              readonly totalVolumeInEth: number | null;
              readonly totalVolumeInUsd: number | null;
            } | null;
            readonly username: string;
          } | null> | null;
          readonly dropPhase: DropPhaseEnum;
          readonly dropTitle: string | null;
          readonly dropsAt: any | null;
          readonly hasVideoPreview: boolean | null;
          readonly heroBackgroundColor: {
            readonly r: number | null;
            readonly g: number | null;
            readonly b: number | null;
          };
          readonly id: string;
          readonly pk: string;
          readonly presenterText: string;
          readonly slug: string | null;
          readonly sortedNftMetadatas: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly dropMetadata: {
                  readonly videoPreviewImageUrl: string | null;
                  readonly previewVideoStartTime: string;
                } | null;
                readonly hasVideo: boolean | null;
                readonly highResImage: string | null;
                readonly id: string;
                readonly mediaMetadata: {
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly previewImage: string | null;
                readonly rawfileExtension: string;
                readonly standardImage: string | null;
                readonly thumbnailImage: string | null;
                readonly title: string;
                readonly videoUrl: string | null;
              } | null;
            } | null>;
          } | null;
          readonly subscribePhoneNumber: string | null;
          readonly totalLimitedEditions: number | null;
          readonly totalOneOfOnes: number | null;
          readonly totalOpenEditions: number | null;
          readonly totalRankedAuctions: number | null;
          readonly heroDescription: string;
        } | null;
        readonly cursor: string;
      } | null>;
    } | null;
  } | null;
  readonly " $fragmentType": "ExploreExhibitionsPaginatedFragment";
};
export type ExploreExhibitionsPaginatedFragment$key = {
  readonly " $data"?: ExploreExhibitionsPaginatedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExploreExhibitionsPaginatedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "exhibition",
  "results"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "artists"
    },
    {
      "kind": "RootArgument",
      "name": "curators"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "nftMetadatasCount"
    },
    {
      "kind": "RootArgument",
      "name": "query"
    },
    {
      "kind": "RootArgument",
      "name": "showFollowingArtists"
    },
    {
      "kind": "RootArgument",
      "name": "sortOrder"
    },
    {
      "kind": "RootArgument",
      "name": "sortType"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PaginatedExploreExhibitionsQuery.graphql')
    }
  },
  "name": "ExploreExhibitionsPaginatedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExploreExhibitionQuery",
      "kind": "LinkedField",
      "name": "exhibition",
      "plural": false,
      "selections": [
        {
          "alias": "results",
          "args": [
            {
              "kind": "Variable",
              "name": "artists",
              "variableName": "artists"
            },
            {
              "kind": "Variable",
              "name": "curators",
              "variableName": "curators"
            },
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "showFollowingArtists",
              "variableName": "showFollowingArtists"
            },
            {
              "kind": "Variable",
              "name": "sortOrder",
              "variableName": "sortOrder"
            },
            {
              "kind": "Variable",
              "name": "sortType",
              "variableName": "sortType"
            },
            {
              "kind": "Variable",
              "name": "status",
              "variableName": "status"
            }
          ],
          "concreteType": "ExhibitionConnection",
          "kind": "LinkedField",
          "name": "__PaginatedExploreExhibitionsQuery__results_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ExhibitionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DropAbstractType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountAbstractType",
                      "kind": "LinkedField",
                      "name": "creators",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "biography",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "followerCount",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fullName",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profileImageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TotalVolumeType",
                          "kind": "LinkedField",
                          "name": "totalVolume",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalVolumeInEth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "totalVolumeInUsd",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dropPhase",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dropTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dropsAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasVideoPreview",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RGB",
                      "kind": "LinkedField",
                      "name": "heroBackgroundColor",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "r",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "g",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "b",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "presenterText",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "first",
                          "variableName": "nftMetadatasCount"
                        }
                      ],
                      "concreteType": "NFTMetadataConnectionTypeConnection",
                      "kind": "LinkedField",
                      "name": "sortedNftMetadatas",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NFTMetadataConnectionTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "NFTMetadataAbstractType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "DropMediaMetadataType",
                                  "kind": "LinkedField",
                                  "name": "dropMetadata",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "videoPreviewImageUrl",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "previewVideoStartTime",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "hasVideo",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "highResImage",
                                  "storageKey": null
                                },
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "NFTMediaMetadataType",
                                  "kind": "LinkedField",
                                  "name": "mediaMetadata",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "height",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "width",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "previewImage",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "rawfileExtension",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "standardImage",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "thumbnailImage",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "title",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "videoUrl",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subscribePhoneNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalLimitedEditions",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalOneOfOnes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalOpenEditions",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalRankedAuctions",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "heroDescription",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7aaa00c7ddd4caecc3cb29e757c24444";

export default node;

import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { AsYouType } from 'libphonenumber-js';
import { useMutation } from 'react-relay';
import { Link } from 'react-router-dom';

import {
  MPActionButton,
  MPAnimations,
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountChangeEmailAndPhoneNumberMutation, {
  AccountChangeEmailAndPhoneNumberMutation$data,
  AccountChangeEmailAndPhoneNumberMutation$variables,
} from 'graphql/__generated__/AccountChangeEmailAndPhoneNumberMutation.graphql';
import { MpErrors } from 'types/__generated__/graphql';

import ROUTES from 'constants/Routes';
import useSession, { useRefreshSession } from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSPadding from 'types/enums/css/Padding';
import { isValidEmail, isValidPhoneNumber } from 'utils/loginUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import Section from '../common/Section';
import LabelWithTooltip from './LabelWithTooltip';

import Session from 'Session';

export default function PersonalSection({
  hideTitle,
}: {
  hideTitle?: boolean;
}) {
  const session = useSession();
  const refreshSession = useRefreshSession();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentEmail = useRef(session.account.email || '');
  const [email, setEmail] = useState(currentEmail.current);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const isEmailValid = useMemo(
    () => !isEmailTouched || (email && isValidEmail(email)),
    [email, isEmailTouched]
  );
  const isEmailChanged = useMemo(
    () => email !== currentEmail.current,
    [email, currentEmail]
  );
  const [usedEmails, setUsedEmails] = useState<Set<string>>(new Set());
  const handleEmailFocus = useCallback(() => setIsEmailTouched(true), []);

  const currentPhoneNumber = useRef(session.account.phoneNumber || '');
  const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber.current);
  const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState(false);
  const isPhoneNumberValid = useMemo(
    () =>
      !isPhoneNumberTouched || (phoneNumber && isValidPhoneNumber(phoneNumber)),
    [phoneNumber, isPhoneNumberTouched]
  );
  const isPhoneNumberChanged = useMemo(
    () => phoneNumber !== currentPhoneNumber.current,
    [phoneNumber, currentPhoneNumber]
  );
  const handlePhoneNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      const formatter = new AsYouType();
      const formattedNumber = formatter.input(inputValue);

      setPhoneNumber(formattedNumber);
    },
    []
  );
  const handlePhoneNumberFocus = useCallback(
    () => setIsPhoneNumberTouched(true),
    []
  );

  const commitChangeEmailAndPhoneNumberAsync = promisifyMutationWithRequestData<
    AccountChangeEmailAndPhoneNumberMutation$variables,
    AccountChangeEmailAndPhoneNumberMutation$data
  >(useMutation(AccountChangeEmailAndPhoneNumberMutation)[0]);
  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const {
        changeEmailAndPhoneNumber: { success },
      } = await commitChangeEmailAndPhoneNumberAsync({
        email: isEmailChanged ? email : undefined,
        phoneNumber: isPhoneNumberChanged ? phoneNumber : undefined,
      });
      if (success) {
        currentEmail.current = email;
        currentPhoneNumber.current = phoneNumber;
        setIsEmailTouched(false);
        setIsPhoneNumberTouched(false);
        await Session.awaitSessionData();
        refreshSession();
      }
    } catch (error) {
      if (error.name === MpErrors.EmailIsAlreadyUsed) {
        setUsedEmails((prevUsedEmails) => new Set(prevUsedEmails).add(email));
      }
    }
    setIsSubmitting(false);
  }, [
    email,
    isEmailChanged,
    phoneNumber,
    isPhoneNumberChanged,
    commitChangeEmailAndPhoneNumberAsync,
    refreshSession,
  ]);

  return (
    <Section
      title={hideTitle ? null : 'Personal Information'}
      contentClassName={CSSGap[16]}
    >
      <div className={joinClasses(CSSGap[24], CSSGlobal.Flex.Col)}>
        <div className={joinClasses(CSSGap[16], CSSGlobal.Flex.Col)}>
          <MPStyledTextField
            label={
              <LabelWithTooltip
                label="Email"
                tooltip="Adding an email will allow you to receive relevant account updates, like bids and offers."
              />
            }
            placeholder="Email"
            value={email}
            setValue={setEmail}
            error={
              usedEmails.has(email)
                ? 'This email is already in use. Please use a different one.'
                : isEmailTouched
                ? !email.trim()
                  ? 'Email address cannot be left blank.'
                  : !isEmailValid
                  ? 'Please enter a valid email address.'
                  : ''
                : ''
            }
            onFocus={handleEmailFocus}
          />
          <MPStyledTextField
            label={
              <LabelWithTooltip
                label="Phone Number"
                tooltip="You can use your phone number to manually subscribe to SMS updates, like for exhibitions and events notifications."
              />
            }
            placeholder="+1 415 444 4444"
            value={phoneNumber}
            error={
              isPhoneNumberTouched
                ? !phoneNumber.trim()
                  ? 'Phone number cannot be left blank.'
                  : !isPhoneNumberValid
                  ? 'Please enter a valid phone number, including the country code.'
                  : ''
                : ''
            }
            type="tel"
            onChange={handlePhoneNumberChange}
            onFocus={handlePhoneNumberFocus}
          />
        </div>

        <MPActionButton
          disabled={
            isSubmitting ||
            (!isEmailChanged && !isPhoneNumberChanged) ||
            (isEmailChanged && !isEmailValid) ||
            (isPhoneNumberChanged && !isPhoneNumberValid)
          }
          fullWidth
          isLoading={isSubmitting}
          size="large"
          variant="primary"
          onClick={handleSubmit}
        >
          Update
        </MPActionButton>
      </div>

      <div
        className={joinClasses(
          MPFonts.paragraphSmall,
          MPBackgroundColorClass.BackgroundDefault,
          CSSPadding.AROUND[24]
        )}
      >
        To change your display name, about bio, and profile images,{' '}
        <Link
          className={joinClasses(
            'hoverableLink',
            'underline',
            MPColorClass.CommonBlack,
            MPAnimations.Color.DarkToLight
          )}
          to={ROUTES.PROFILE(session.account.username)}
        >
          edit your profile
        </Link>
        .
      </div>
    </Section>
  );
}

/* eslint-disable-next-line import/prefer-default-export */
export enum PAGESLUG {
  'account' = 'account',
  'activity' = 'activity',
  'collection' = 'collection',
  'contracts' = 'contracts',
  'creditCards' = 'credit-cards',
  'saved' = 'saved',
  'security' = 'security',
  'wallets' = 'wallets',
  'withdrawal' = 'withdrawal',
}

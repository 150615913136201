import { useEffect, useRef, useState } from 'react';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay';
import { useAccount, useSwitchChain } from 'wagmi';
import { useStatsigClient } from '@statsig/react-bindings';

import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import OpenEditionBeforePurchaseInETHMutation, {
  OpenEditionBeforePurchaseInETHMutation$data,
  OpenEditionBeforePurchaseInETHMutation$variables,
} from 'graphql/__generated__/OpenEditionBeforePurchaseInETHMutation.graphql';
import OpenEditionPurchaseInCCMutation, {
  OpenEditionPurchaseInCCMutation$data,
  OpenEditionPurchaseInCCMutation$variables,
} from 'graphql/__generated__/OpenEditionPurchaseInCCMutation.graphql';
import OpenEditionPurchaseInETHMutation, {
  OpenEditionPurchaseInETHMutation$data,
  OpenEditionPurchaseInETHMutation$variables,
} from 'graphql/__generated__/OpenEditionPurchaseInETHMutation.graphql';

import { useTrackingContext } from 'components/trackingContext';
import { STATSIG_EVENT } from 'constants/Statsig';
import GTM, { EcommercePaymentType, EcommercePurchaseType } from 'GTM';
import useDepositFundContract from 'hooks/contracts/useDepositFundContract';
import useLogging from 'hooks/useLogging';
import useSession from 'hooks/useSession';
import usePublicClient from 'hooks/useWeb3Client';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { getSafeWeiAmountFromEthUserInput } from 'utils/EthPriceUtils';
import { HexString } from 'utils/jwt/walletUtils';
import { NFTCardSelectionType } from 'utils/nftUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import {
  CreditCardPaymentFormState,
  FormState,
  usePaymentFormState,
  useStripePayment,
  useWalletConnectionState,
} from './usePaymentFormState';
import {
  DEFAULT_DEPOSIT_FUND_STATE,
  DepositFundState,
} from './usePurchaseProduct';

export const usePurchaseOpenEditionWithCreditCard = ({
  cardHolderName,
  cardIsValid,
  cardSelectionType,
  remember,
  savedCardId,
  openEditionId,
  count,
  isPresale,
  onSuccess,
  priceInUsd,
  priceInEth,
}: CreditCardPaymentFormState & {
  count: number;
  isPresale: boolean;
  onSuccess: () => void;
  openEditionId: number;
  priceInEth: number;
  priceInUsd: number;
}): [() => Promise<void>, FormState, () => void] => {
  const statsigClient = useStatsigClient();
  const [formState, updateFormState, resetFormState] = usePaymentFormState();
  const { logNFTException } = useLogging();
  const { getPaymentMethodId, withNextPaymentAction } = useStripePayment();
  const { source, title, username } = useTrackingContext();

  const [commitPurchaseMutation] = useMutation(OpenEditionPurchaseInCCMutation);
  const commitPurchaseAsync = withNextPaymentAction<
    OpenEditionPurchaseInCCMutation$variables,
    OpenEditionPurchaseInCCMutation$data
  >(promisifyMutationWithRequestData(commitPurchaseMutation));

  const purchase = async (): Promise<void> => {
    statsigClient.logEvent(
      STATSIG_EVENT.MP.OPEN_EDITION.BEGIN_CHECKOUT,
      priceInUsd,
      {
        count: `${count}`,
        is_presale: `${isPresale}`,
        open_edition_id: `${openEditionId}`,
        open_edition_name: title,
        open_edition_price_eth: `${priceInEth}`,
        open_edition_price_usd: `${priceInUsd}`,
        payment_type: EcommercePaymentType.CreditCard,
      }
    );
    resetFormState();

    try {
      updateFormState({ isValidating: true });

      const paymentMethodId = await getPaymentMethodId(
        cardSelectionType,
        savedCardId,
        cardHolderName
      );

      const purchaseResult = await commitPurchaseAsync({
        count,
        isPresale,
        openEditionId,
        paymentMethodId,
        rememberCard: remember,
      });

      if (!purchaseResult.openEditionPurchaseInCc.success) {
        throw new Error(`Error purchasing open edition`);
      }

      updateFormState({ isSuccess: true, isValidating: false });
      onSuccess();
      GTM.ecommerce.purchaseOpenEdition(
        title,
        priceInEth,
        priceInUsd,
        openEditionId,
        username,
        count,
        source,
        CurrencyDisplayMode.USD
      );
      statsigClient.logEvent(
        STATSIG_EVENT.MP.OPEN_EDITION.PURCHASE_SUCCESS,
        priceInUsd,
        {
          count: `${count}`,
          is_presale: `${isPresale}`,
          open_edition_id: `${openEditionId}`,
          open_edition_name: title,
          open_edition_price_eth: `${priceInEth}`,
          open_edition_price_usd: `${priceInUsd}`,
          payment_type: EcommercePaymentType.CreditCard,
        }
      );
    } catch (error) {
      updateFormState({
        mutationError: error,
      });
      logNFTException(
        openEditionId,
        `Error Purchasing Open Edition through CC: ${error}`
      );
      GTM.ecommerce.error(error.toString(), {
        offer_or_purchase: EcommercePurchaseType.Purchase,
      });
      statsigClient.logEvent(
        STATSIG_EVENT.MP.OPEN_EDITION.PURCHASE_ERROR,
        priceInUsd,
        {
          count: `${count}`,
          is_presale: `${isPresale}`,
          open_edition_id: `${openEditionId}`,
          open_edition_name: title,
          open_edition_price_eth: `${priceInEth}`,
          open_edition_price_usd: `${priceInUsd}`,
          payment_type: EcommercePaymentType.CreditCard,
        }
      );
    }
  };

  return [
    purchase,
    {
      ...formState,
      isDisabled: !(
        (cardSelectionType === NFTCardSelectionType.New &&
          !!cardHolderName &&
          cardIsValid) ||
        (cardSelectionType === NFTCardSelectionType.Saved && !!savedCardId)
      ),
    },
    resetFormState,
  ];
};

export const usePurchaseOpenEditionWithEthereum = ({
  openEditionId,
  depositFundContractQueryRef,
  count,
  priceInEth,
  priceInUsd,
  isPresale,
}: {
  count: number;
  depositFundContractQueryRef: PreloadedQuery<NFTContractQuery>;
  isPresale: boolean;
  openEditionId: number;
  priceInEth: number;
  priceInUsd: number;
}): [() => Promise<void>, FormState, () => void, string, () => void] => {
  const statsigClient = useStatsigClient();
  const provider = usePublicClient();
  const session = useSession();
  const { address: buyerAddress } = useAccount();
  const walletConnectionState = useWalletConnectionState();
  const { switchChainAsync } = useSwitchChain();
  const { logNFTException } = useLogging();
  const [emailAddress] = useState(session.account?.email || '');
  const [formState, updateFormState, resetFormState] = usePaymentFormState();
  const { source, title, username } = useTrackingContext();
  const depositFundState = useRef<DepositFundState>({
    ...DEFAULT_DEPOSIT_FUND_STATE,
  });
  const [transactionHash, setTransactionHash] = useState<string>(null);
  const resetTransactionHash = () => setTransactionHash(null);

  const { nftContract: depositFundManagerContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      depositFundContractQueryRef
    );

  const { useDeposit } = useDepositFundContract({
    abi: JSON.parse(depositFundManagerContract.abidata).abi,
    contractAddress: depositFundManagerContract.address as HexString,
  });

  const depositContractManager = useDeposit({
    metadataId: depositFundState.current.metadataId,
    requestId: depositFundState.current.requestId,
    value: getSafeWeiAmountFromEthUserInput(priceInEth * count),
  });

  const [commitBeforePurchaseInEthereumMutation] = useMutation(
    OpenEditionBeforePurchaseInETHMutation
  );
  const commitBeforePurchaseInEthereumMutationAsync =
    promisifyMutationWithRequestData<
      OpenEditionBeforePurchaseInETHMutation$variables,
      OpenEditionBeforePurchaseInETHMutation$data
    >(commitBeforePurchaseInEthereumMutation);

  const [commitPurchaseInEthereumMutation] = useMutation(
    OpenEditionPurchaseInETHMutation
  );
  const commitPurchaseInEthereumMutationAsync =
    promisifyMutationWithRequestData<
      OpenEditionPurchaseInETHMutation$variables,
      OpenEditionPurchaseInETHMutation$data
    >(commitPurchaseInEthereumMutation);

  const handleBeforePurchaseOpenEdition = async (): Promise<void> => {
    depositFundState.current.transactionInProgress = true;
    const result = await commitBeforePurchaseInEthereumMutationAsync({
      buyerAddress,
      count,
      emailAddress,
      isPresale,
      openEditionId,
    });
    if (!result.openEditionBeforePurchaseInEth.success) {
      throw new Error(`Error fetching before purchase open edition`);
    } else {
      depositFundState.current = {
        ...depositFundState.current,
        metadataId:
          result.openEditionBeforePurchaseInEth.response.depositMetadataId,
        requestId:
          result.openEditionBeforePurchaseInEth.response.depositRequestId,
      };
    }
    depositFundState.current.transactionInProgress = false;
  };

  useEffect(() => {
    const {
      simulate: { isFetching, isPending },
    } = depositContractManager;
    const { requestId, metadataId, transactionInProgress } =
      depositFundState.current;
    if (
      buyerAddress &&
      session.isLoggedIn() &&
      !isFetching &&
      !isPending &&
      !transactionInProgress &&
      (requestId === 0 || metadataId === 0)
    ) {
      handleBeforePurchaseOpenEdition();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [depositContractManager, buyerAddress, session]);

  const purchase = async (): Promise<void> => {
    statsigClient.logEvent(
      STATSIG_EVENT.MP.OPEN_EDITION.BEGIN_CHECKOUT,
      priceInUsd,
      {
        count: `${count}`,
        is_presale: `${isPresale}`,
        open_edition_id: `${openEditionId}`,
        open_edition_name: title,
        open_edition_price_eth: `${priceInEth}`,
        open_edition_price_usd: `${priceInUsd}`,
        payment_type: EcommercePaymentType.ETH,
      }
    );

    resetFormState();
    try {
      if (depositFundState.current.transactionInProgress) {
        return;
      }
      if (!walletConnectionState.isConnected) {
        return;
      }
      if (walletConnectionState.isConnectedToWrongNetwork) {
        await switchChainAsync({ chainId: session.contractNetwork });
      }
      depositFundState.current.transactionInProgress = true;
      updateFormState({ isValidating: true });
      const nonce = (await provider.getTransactionCount(buyerAddress)) + 1;
      const transactionResult =
        await depositContractManager.mutate.writeAsync();
      setTransactionHash(transactionResult);
      const result = await commitPurchaseInEthereumMutationAsync({
        buyerAddress,
        count,
        depositMetadataId: depositFundState.current.metadataId,
        emailAddress,
        nonce,
        openEditionId,
        transactionId: transactionResult,
      });
      if (!result.openEditionPurchaseInEth.success) {
        throw new Error(`Error purchasing open edition`);
      }
      depositFundState.current.transactionInProgress = false;
      updateFormState({ isSuccess: true, isValidating: false });
      GTM.ecommerce.purchaseOpenEdition(
        title,
        priceInEth,
        priceInUsd,
        openEditionId,
        username,
        count,
        source,
        CurrencyDisplayMode.ETH
      );
      statsigClient.logEvent(
        STATSIG_EVENT.MP.OPEN_EDITION.PURCHASE_SUCCESS,
        priceInUsd,
        {
          count: `${count}`,
          is_presale: `${isPresale}`,
          open_edition_id: `${openEditionId}`,
          open_edition_name: title,
          open_edition_price_eth: `${priceInEth}`,
          open_edition_price_usd: `${priceInUsd}`,
          payment_type: EcommercePaymentType.ETH,
        }
      );
    } catch (error) {
      updateFormState({
        mutationError: error,
      });
      logNFTException(
        openEditionId,
        `Error Purchasing Open Edition through ETH: ${error}`
      );
      GTM.ecommerce.error(error.toString(), {
        offer_or_purchase: EcommercePurchaseType.Purchase,
      });
      depositFundState.current.transactionInProgress = false;
      updateFormState({ isValidating: false });
      depositContractManager.mutate.reset();
      statsigClient.logEvent(
        STATSIG_EVENT.MP.OPEN_EDITION.PURCHASE_ERROR,
        priceInUsd,
        {
          count: `${count}`,
          is_presale: `${isPresale}`,
          open_edition_id: `${openEditionId}`,
          open_edition_name: title,
          open_edition_price_eth: `${priceInEth}`,
          open_edition_price_usd: `${priceInUsd}`,
          payment_type: EcommercePaymentType.ETH,
        }
      );
    }
  };

  return [
    purchase,
    {
      ...formState,
      isDisabled:
        !walletConnectionState.isConnected ||
        depositContractManager.simulate.isFetching ||
        depositContractManager.simulate.isPending ||
        depositContractManager.mutate.isPending,
    },
    resetFormState,
    transactionHash,
    resetTransactionHash,
  ];
};

import { useGateValue } from '@statsig/react-bindings';

import useSession from 'hooks/useSession';

import useSetDocTitle from '../useSetDocTitle';
import PersonalSection from './personal';
import SecuritySection from './security';

export default function AccountPageSplitter() {
  useSetDocTitle('Account');
  useSession().contracts.preLoad();

  const showNewCollectionDesign = useGateValue('gate_settings_v3_collection');

  return showNewCollectionDesign ? (
    <PersonalSection hideTitle />
  ) : (
    <>
      <PersonalSection />
      <SecuritySection />
    </>
  );
}

import { ReactNode, useEffect } from 'react';
import {
  StatsigProvider as BaseStatsigProvider,
  useClientAsyncInit,
  useStatsigClient,
} from '@statsig/react-bindings';
import {
  SessionReplay,
  StatsigSessionReplayPlugin,
} from '@statsig/session-replay';
import { StatsigAutoCapturePlugin } from '@statsig/web-analytics';

import useSession from 'hooks/useSession';

import { SiteLoader } from './SiteLoadingSuspense';

let sessionReplay: SessionReplay = null;
export function useForceStatsigSessionReplayRecording() {
  const statsig = useStatsigClient();

  useEffect(() => {
    if (sessionReplay || statsig.client.loadingStatus !== 'Ready') {
      return;
    }

    sessionReplay = new SessionReplay(statsig.client);
    sessionReplay.forceStartRecording();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

type StatsigUser = {
  email?: string;
  userID?: string;
};

export const convertAccountToStatsigUser = (
  account: Partial<{
    email: string;
    pk: string;
  }> = {}
): StatsigUser => ({
  email: account?.email,
  userID: account?.pk,
});

export default function StatsigProvider({ children }: { children: ReactNode }) {
  const session = useSession();
  const { client } = useClientAsyncInit(
    session.statsigClientKey,
    convertAccountToStatsigUser(session.account),
    {
      environment: {
        tier: session.environment as 'production' | 'staging' | 'development',
      },
      plugins: [
        new StatsigSessionReplayPlugin(),
        new StatsigAutoCapturePlugin(),
      ],
    }
  );

  return (
    <BaseStatsigProvider client={client} loadingComponent={<SiteLoader />}>
      {children}
    </BaseStatsigProvider>
  );
}

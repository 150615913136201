import BaseMarkdown from 'markdown-to-jsx';

import { MPAnimations, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

function CustomLink({ href, children, ...props }) {
  return (
    <a
      {...props}
      href={href}
      className={joinClasses(
        'defaultLink',
        'underline',
        MPAnimations.Color.DarkToLight
      )}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

function Paragraph({ children }) {
  return <p className="reset">{children}</p>;
}

function Strong({ children }) {
  return <b className={MPFonts.textSmallSemiBold}>{children}</b>;
}

interface MarkdownProps {
  children: string;
  className?: string;
}

export default function Markdown({ children, className }: MarkdownProps) {
  return (
    <BaseMarkdown
      options={{
        overrides: {
          a: {
            component: CustomLink,
            props: { className },
          },
          blockquote: {
            component: Paragraph,
            props: { className },
          },
          p: {
            component: Paragraph,
            props: { className },
          },
          strong: {
            component: Strong,
            props: { className },
          },
        },
      }}
    >
      {children}
    </BaseMarkdown>
  );
}
